import {USER_API_TOKEN} from "../../constants/URLS";
import {APIToken, APITokenCreateReply} from "../../utils/types";

export const createAPIToken = (t:APIToken):Promise<APITokenCreateReply | null> => {
    return fetch(
        USER_API_TOKEN,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(t)
        })
        .then(async resp => {
            const response:APITokenCreateReply = await resp.json();
            return response
        })
        .catch(async error => {
            console.log(error)
            return null
        })
}
