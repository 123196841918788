import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import {colors} from "../styles/colors";
import {SearchLabelDataType} from "../utils/types";
import PropTypes from "prop-types";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    searchResult: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        marginBottom: '15px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.navSelected,
            '& .arrow': {
                border: 'solid white',
                borderWidth: '0 3px 3px 0',
            },
            '& $header, & $subHeader': {
                color: 'white',
            },
            '& svg path': {
                fill: 'white',
            },
            '& $label': {
                borderColor: colors.navSelected
            }
        }
    },
    searchResultToggled: {
        marginBottom: '0',
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
    },
    searchResultHeader: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    searchResultHeaders: {
        width: '100%',
    },
    headerWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    header: {
        fontSize: '1rem',
        color: '#000',
    },
    label: {
        fontSize: '0.7rem',
        marginLeft: '15px',
        border: '1px solid #eee',
        borderRadius: '20px',
        padding: '0 8px',
        color: 'white',
        fontWeight: 'bold'
    },
    rawData: {
        backgroundColor: '#006280'
    },
    processed: {
        backgroundColor: 'green'
    },
    notUsable: {
        backgroundColor: '#e8501f'
    },
    subHeader: {
        fontSize: '0.8rem',
        color: '#000',
    },
    arrowDiv: {
        minWidth: '20px',
    },
    resultData: {
        width: '100%',
        marginBottom: '15px',
    },
    show: {
        display: 'block',
        '& > div': {
            backgroundColor: 'white',
        },
        '& textarea': {
            color: 'black',
            padding: '10px'
        },
        '& > div > div': {
            padding: '0',
            borderRadius: '0',
        },
        '& fieldset': {
        }
    },
    hide: {
        display: 'none',
    }
}));

export default function SearchResult(props) {
    const classes = useStyles();
    const [toggled, setToggled] = useState<boolean>(false);
    const handleClick = (e) => {
        if (props.disabled) return;
        if (e.target.name !== 'searchCheckBox') {
            setToggled(!toggled)
        }
    }

    return (
        <div>
            <div className={toggled ? `${classes.searchResult} ${classes.searchResultToggled}`
                : classes.searchResult} onClick={handleClick}>
                <Checkbox
                    checked={props.checked}
                    onChange={props.onChange}
                    name="searchCheckBox"
                    disabled={props.disabled}
                    color="primary"/>
                <div className={classes.searchResultHeader}>
                    <div className={classes.searchResultHeaders}>
                        <div className={classes.headerWrapper}>
                            <div className={`${classes.header}`}>
                                {props.header}
                            </div>
                            <div className={ props.label === SearchLabelDataType.Raw ?
                                `${classes.label} ${classes.rawData}` : `${classes.label} ${classes.processed}` }>
                                { props.label === SearchLabelDataType.Raw ? "raw data" :"processed data" }
                            </div>
                            {props.isResearchUsable ? null :
                                <div className={`${classes.label} ${classes.notUsable}`}>not usable for research</div>}
                        </div>
                        <div className={`${classes.subHeader}`}>
                            {props.subHeader}
                        </div>
                    </div>
                    <div className={classes.arrowDiv}>
                        <div className={toggled ? `arrow arrow--down` : `arrow arrow--left` }/>
                    </div>
                </div>
            </div>
            <div className={toggled ? classes.show : classes.hide}>
                <TextField
                    className={classes.resultData}
                    id="validation-response"
                    multiline
                    rows={16}
                    disabled={true}
                    value={JSON.stringify(props.data, null, 2)}
                    variant="outlined"
                />
            </div>
        </div>
    );
}

SearchResult.propTypes = {
    checked: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    label: PropTypes.number.isRequired,
    isResearchUsable: PropTypes.bool.isRequired,
    subHeader: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
};
