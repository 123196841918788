import { AppContextInterface, SearchCheckedItems, UnitSizes } from "./types";
import { refreshToken } from "../actions/user/refreshToken";
import { SERVER_TOKEN } from "../constants/URLS";

export const RefreshToken = async (app: AppContextInterface, force?: boolean): Promise<string> => {
    if (process.env.NODE_ENV === 'development') {
        return SERVER_TOKEN;
    }

    if (app.user.token.expiry.getTime() > Date.now() && (force === undefined || !force)) {
        return app.user.token.value;
    }

    let newToken = await refreshToken(app)
    if (!newToken) {
        app.setSnackBar({
            open: true,
            msg: "Server error, please try again later.",
            variant: "error",
            timeout: 3000,
        });
        return '';
    }
    return newToken;
}

export const FormatMetadataTimestamp = (ts: string) => {
    // show sample's local time
    // 2021-05-01T16:26:09+0100 -> 2021-05-01 16:26:09
    const re = /(.+)T(.+)(Z|\+)/i
    const match = ts.match(re)
    if (match && match.length === 4) {
        return match[1] + " " + match[2];
    }
    return ts
    // return moment(ts).local().format("DD.MM.YYYY HH:mm:ss")
}

const dateNumDoubleDigit = (n: number): string => {
    return n < 10 ? "0" + n : String(n)
}

export const DateToString = (d: Date): string => {
    return d.getFullYear() + "-" + dateNumDoubleDigit(+d.getMonth() + 1) + "-" +
        dateNumDoubleDigit(d.getDate()) + "T" + dateNumDoubleDigit(d.getHours()) +
        ":" + dateNumDoubleDigit(d.getMinutes()) + ":" + dateNumDoubleDigit(d.getSeconds()) + "Z"
}

export const ConvertToUnit = (size: number, fromUnit: UnitSizes, toUnit: UnitSizes): number => {
    if (size < 0 || size === 0) return 0;
    const unitSize = {
        "KB": 0.001,
        "MB": 1,
        "GB": 1000,
        "TB": 1000000
    }
    return size * unitSize[UnitSizes[fromUnit]] / unitSize[UnitSizes[toUnit]];
}


export const setDefaultCheckedState = (arr: string[], value: boolean) => {
    let checkedStations = {};
    arr.forEach(s => { checkedStations[s] = value })
    return checkedStations
}

export const getCheckedItemsNames = (items: SearchCheckedItems): string[] => {
    let names: string[] = [];
    for (const k in items) {
        if (items.hasOwnProperty(k)) {
            if (items[k] === true) {
                names.push(k)
            }
        }
    }
    return names;
}

export const FormatDateToDay = (date: Date) => {
    return date.getDate() + '.' + Number(date.getMonth() + 1) + '.' + date.getFullYear();
}
