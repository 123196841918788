import {BASE_STATIONS_INFO} from "../../constants/URLS";
import {BaseStationsReply, BaseStationInterface} from "../../utils/types";

export const getBaseStations = async ():Promise<BaseStationsReply> => {
        const capitalizeType = (type:string):string => {
            let ct:string = '';
            let words = type.split(" ");
            words.forEach((w, i) => {
                if (i !== 0) { ct += ' ' }
                ct += w.charAt(0).toUpperCase() + w.slice(1);
            })
            return ct;
        }

        const onSuccess = (data:BaseStationInterface[]) => {
            let allSensors:string[] = [];
            data.forEach(d => {
                d.sensors.forEach(s => {
                    let name = capitalizeType(s.type);
                    if (allSensors.indexOf(name) === -1) {
                        allSensors.push(name);
                    }
                })
            })
            data.sort((a,b) => (a.longName > b.longName) ? 1
                : ((b.longName > a.longName) ? -1 : 0));
            allSensors.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
            return {
                baseStations: data,
                sensorNames: allSensors,
                error: false,
            }
        }

        try {
            const data:BaseStationInterface[] = await fetch(
                BASE_STATIONS_INFO,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(resp => resp.json());

            if (data.length === 0) {
                return {
                    baseStations: [],
                    sensorNames: [],
                    error: false,
                };
            }

            return onSuccess(data);

        } catch (error) {
            console.log(error)
            return {
                baseStations: [],
                sensorNames: [],
                error: true,
            };
        }
}
