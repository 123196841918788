import {TELEMETRY_URL} from "../../constants/URLS";
import {
    BaseStationInterface,
    ErrorResponse,
    SnackbarMessage,
    TelemetryRawRecord,
    TelemetryReply,
    TelemetryServerReply,
} from "../../utils/types";


const bsFromDeviceID = (deviceID:string, baseStations:BaseStationInterface[]):string => {
    let response:string = "";
    
    baseStations.forEach(bs => {
        if (response !== "") {
            return;
        }

        if (bs.id === deviceID) {
            response = "bs_" + bs.longName.slice(-3);
            return;
        }

        bs.sensors.forEach(s => {
            if (response !== "") {
                return;
            }
            
            if (s.id === deviceID) {
                response = "bs_" + bs.longName.slice(-3);
                return;
            }
   
        })
    })
    
    return response
}

export const getTelemetry = async (widget_title:string, params:string[][],
    setSnackBar:(msg: SnackbarMessage) => void, baseStations:BaseStationInterface[] ):Promise<TelemetryReply> => {
    const url = TELEMETRY_URL + '?' + new URLSearchParams(params).toString();
    const showErr = (msg:string) => {
        setSnackBar({
            open:true,
            msg: msg,
            variant: "error",
            timeout: 3000,
        });
    }

    try {
        const response:TelemetryServerReply|ErrorResponse = await fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(resp => resp.json());

        if (!('data' in response)) {
            showErr(widget_title + ": " +response.error)
            return {}
        }

        if (Object.keys(response.data).length === 0) {
            return {};
        }

        let results:TelemetryReply = {};
        for (const deviceID in response.data) {
            if (response.data.hasOwnProperty(deviceID)) {
                let bsName = bsFromDeviceID(deviceID, baseStations);
                response.data[deviceID].forEach( ( data:TelemetryRawRecord ) => {
                    let label =  data.label.length > 0 ? `${bsName}: ${data.label}` : bsName;
                    if (!results[label]) {
                        results[label] = [];
                    }

                    results[label].push({
                        label: label,
                        x: data.timestamp,
                        y: data.value
                    })
                })
            }
        }
        return results

    } catch (error) {
        console.log(error)
        showErr("Server error, please try again later");
        return {};
    }

}
