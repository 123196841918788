import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

//@ts-ignore
const DialogTitle = withStyles(styles)((props) => {
    //@ts-ignore
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function DialogWrapper(props) {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={props.maxWidth || "md"}
            open={props.open}
            onClose={props.onClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle
                //@ts-ignore
                id="scroll-dialog-title" onClose={props.onClose}>{props.title}</DialogTitle>
            <DialogContent dividers={true} id="scroll-dialog-description" tabIndex={-1}>
                {props.children}
            </DialogContent>
        </Dialog>
    );
}

DialogWrapper.propTypes = {
    maxWidth: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
};

