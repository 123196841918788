// token
export interface Token {
    value: string,
    expiry: Date,
}

// logged in user
export interface DjangoUser {
    name: string,
    email: string,
    token: string,
    expiry: string,
    upload: string,
    download: string,
    support_email: string,
    telemetry_fields: TelemetryField[],
}
export interface User {
    name: string,
    email: string,
    token: Token,
    upload: boolean,
    download: boolean,
    support_email: string,
}
// END USERS //

// telemetry fields
export interface TelemetryField {
    sensor: string,
    name: string,
    field_name: string,
    field_label: string,
}

// upload chunk
export enum UploadChunkStatusEnum {
    MissingFiles,
    OK,
    UploadError,
    UploadSuccess,
    Uploading,
    SyntaxError,
    MultipleJsonFileNameError
}

export interface UploadChunkStatus {
    code: UploadChunkStatusEnum,
    message: string,
}

export interface UploadChunk {
    name: string, // name of the json file
    files: File[],
    metadataFileName: string,
    status: UploadChunkStatus,
    progress: number,
}

interface MetaFile {
    fileName: string,
    fileSize: number,
    md5Checksum: string,
}

export interface MetaJson {
    file: File,
    dir: string,
    data: {
        files: MetaFile[],
    },
    syntaxError: string,
}

export interface FileInfo {
    file: File,
    path: string,
}

// app
export interface AppContextInterface {
    isLoading: boolean,
    user: User,
    setUser: (user: User) => void,
    baseStations: BaseStationInterface[],
    setBaseStations: (baseStations: BaseStationInterface[]) => void,
    sensors: string[],
    setSensors: (sensors: string[]) => void,
    setSnackBar: (snackBar: SnackbarMessage) => void,
    searchBaseStation: BaseStationInterface|null,
    setSearchBaseStation: (baseStation: BaseStationInterface | null) => void,
    telemetryFields: TelemetryField[],
}

// sensor info
export interface SensorInterface {
    id: string,
    serialNumber: string,
    lastModified: string,
    type: string,
    citation: string,
    model: string,
    status: string,
}

// base station info
export interface BaseStationInterface {
    id: string,
    lastModified: string,
    longName: string,
    citation: string,
    type: string,
    position: {
        long: number,
        lat: number
    },
    status: string,
    sensors: SensorInterface[]
}

export interface BaseStationsReply {
    baseStations: BaseStationInterface[],
    sensorNames: string[],
    error: boolean,
}

// snackbar message
export interface SnackbarMessage {
    open: boolean,
    msg: string,
    timeout: number|null,
    variant: "success" | "error" | "info" | "warning";
}

// refresh token response
export interface RefreshToken {
    token: string,
    expiry: string,
}

export enum ChartType {
    Line,
    Bar,
}

export const logicalOperatorValues = [
    {
        string: "=",
        query: "eq"
    },
    {
        string: "<",
        query: "lt"
    },
    {
        string: ">",
        query: "gt"
    },
    {
        string: "<=",
        query: "lte"
    },
    {
        string: ">=",
        query: "gte"
    },
    {
        string: "!=",
        query: "ne"
    },
]

export enum logicalOperator {
    equal,
    less,
    greater,
    lessEqual,
    greaterEqual,
    notEqual,
}

export interface CustomSearch {
    key: string,
    value: string,
    operator: logicalOperator,
}

export interface SearchCheckedItems {
    [key: string]: boolean
}

// what we receive from the backend server
export interface SearchResultRaw {
    file_size: number,
    count: number,
    pages: number,
    data: SearchResultData[],
    total_records: number,
}

interface metaDataTimeStamp {
    start: string,
    stop: string,
}

interface file {
    fileName: string,
    fileSize: number,
    md5Checksum: string,
}

// body of the metadata
export interface SearchResultData {
    _id: string,
    deviceID: string,
    serialNumber: string,
    timestamp: metaDataTimeStamp,
    usableForResearchPurposes: boolean,
    files: file[],
    sourceFiles: string[],
    [k: string]: string | number | Object
}

// merged metadata with checked status
export interface SearchResult {
    checked: boolean,
    data: SearchResultData,
}

// final results of the search
export interface SearchResults {
    info: SearchResultsInfo,
    data: SearchResult[],
}

export enum UnitSizes {
    KB,
    MB,
    GB,
    TB
}

export interface SearchResultsInfo {
    total_size: number,
    file_size: number,
    size_unit: UnitSizes,
    count: number,
    pages: number,
    total_records: number,
}

export interface OriginalSearchSize {
    file_size: number,
    size_unit: UnitSizes,
}

export interface DownloadResponse {
    url: string,
    error?:string,
}

export interface SearchFilterInfo {
    params: string[][],
    new_search: boolean,
    shared_query: boolean,
}

export interface ChardDataItem {
    x: string,
    y: number
}

export interface ChartDataSet {
    label?: string,
    fill: boolean,
    data: ChardDataItem[] | number[],
    borderColor: string,
    backgroundColor: string,
    borderWidth?: number,
    barPercentage?: number,
    barThickness?: number,
    maxBarThickness?: number,
    minBarLength?: number,
}

export interface ChartData {
    labels?: string[],
    datasets: ChartDataSet[]
}

export interface WidgetInfo {
    title: string,
    sensorName: string,
    startDate: Date,
    stopDate: Date,
    sensors: string[],
    locations: string[],
    fullKey: string,
    labelKey: string,
    key: string,
    type: ChartType,
    customSearch: CustomSearch[],
    params: string[][],
}

export interface TelemetryServerReply {
    data: TelemetryRawData
}

export interface TelemetryRawData {
    [k: string]: TelemetryRawRecord[],
}

export interface TelemetryRawRecord {
    label: string,
    timestamp: string,
    value: number,
}

export interface TelemetryReply {
    [k: string]: TelemetryData[],
}

export interface TelemetryData {
    label: string,
    x: string,
    y: number,
}

export interface Legend {
    activeBaseStations: number,
    inactiveBaseStations: number,
    bioSensors: number,
    cameraSensors: number,
    smellscapeSensors: number,
    pollenSensors: number,
    malaiseSensors: number,
    weatherSensors: number,
}

export enum SearchLabelDataType {
    Raw,
    Processed,
    NotUsable
}

export interface SensorInfo {
    deviceID: string,
    type: string,
    model: string,
    citation: string,
    baseStationID: string,
    baseStationLongName: string,
    baseStationCitation: string,
    status: string
}

export interface SelectorItem {
    value: string,
    label: string,
}

export interface HomeFilter {
    column: string,
    operator: string,
    value: string,
}

export interface FilterContextInterface {
    reset: boolean,
    setReset: (reset: boolean) => void,
}

export interface APIToken {
    name: string,
    created_at: string,
    has_download: boolean,
    has_upload: boolean
}

export interface APITokenCreateReply {
    access_token: string,
    refresh_token: string,
    expiry: string,
}

export interface SuccessResponse {
    success: boolean
}

export interface ErrorResponse {
    error: string
}