import React, { useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme, withStyles} from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Typography as MyTypography, Elements, Inputs} from "../styles";
import NavBar from "../components/NavBar";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MySnackBar from "../components/Snackbar";

import {APIToken, APITokenCreateReply, SnackbarMessage, SuccessResponse} from "../utils/types";
import { DialogActions, Button, TextField, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Paper, Dialog, DialogContent, DialogContentText, DialogTitle, CircularProgress } from "@material-ui/core";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DialogWrapper from '../components/DialogWrapper';
import useDidMountEffect from '../CustomHooks/useDidMountEffect';
import { AppContext } from '../App';
import { getAPITokens } from '../actions/tokens/get';
import { createAPIToken } from '../actions/tokens/create';
import VpnKeySharp from '@material-ui/icons/VpnKeySharp';
import { deleteAPIToken } from '../actions/tokens/delete';

const useStyles = makeStyles(theme => ({
    main: {
        margin: '45px 0',
        [theme.breakpoints.up('md')]: {
            margin: '0'
        },
    },
    appBar: {
        backgroundColor: 'white',
        color: 'black'
    },
    permissionsWrapper: {
        marginTop: '25px'
    },
    permissions: {
        flexDirection: 'row'
    },
    apiTokenWrapper: {
        display: 'flex'
    },
    apiTokenIcon: {
        margin: 'auto 10px',
        '& svg': {
            height: '2em',
            width: '2em',
            fill: '#65bc7b',
        }
    },
    apiTokenInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        flexGrow: 1,
        overflow: 'hidden',
    },
    apiTokenInfoName: {
        fontWeight: 'bold',
        fontSize: '1.2em',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    deleteButtonWrapper: {
        margin: 'auto 10px'
    },
    deleteButton: {
        fontWeight: 'bold',
        maxWidth: '80px',
        maxHeight: '30px',
        color: 'white',
        backgroundColor: "red",
        '&:hover': {
            backgroundColor: "red",
            color: '#fff'
        }
    },
    keysWrapper: {
        display: 'flex'
    },
    keysTitle: {
        fontWeight: 'bold',
        marginRight: '20px'
    },
    spinner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        minWidth: '100%',
        position: 'fixed',
        top: '0',
        left: '140px',
        [theme.breakpoints.down('sm')]: {
            left: '0',
        },
    },
}))

const Tokens = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const app = useContext(AppContext);
    const [snackBar, setSnackBar] = useState<SnackbarMessage>({
        open: false,
        msg: '',
        variant: 'info',
        timeout: 3000,
    });
    const [showCreateToken, setShowCreateToken] = useState<boolean>(false);
    const [showTokenKeys, setShowTokenKeys] = useState<boolean>(false);
    const [apiTokens, setApiTokens] = useState<APIToken[]>([])
    const [newToken, setNewToken] =  useState<APIToken>({
        name: '',
        has_upload: false,
        has_download: false,
        created_at: '',
    })
    const [tokenKeys, setTokenKeys] = useState<APITokenCreateReply>({
        access_token: '',
        refresh_token: '',
        expiry: ''
    })
    const [loading, setLoading] = useState<boolean>(true)

    const setEmptyNewToken = () => {
        setNewToken({
            name: '',
            has_upload: false,
            has_download: false,
            created_at: '',
        })
    }

    const showServerError = () => {
        setSnackBar({
            open: true,
            msg: 'Server error, please try again later.',
            variant: 'error',
            timeout: 3000,
        });
    }

    const handleClose = () => {
        setSnackBar({
            open: false,
            msg: '',
            variant: 'info',
            timeout: 3000,
        });
    }

    const handleCreateTokenClose = (e, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        setEmptyNewToken()
        setShowCreateToken(false)
    }

    useDidMountEffect( async () => {     
        // load tokens
        const api_tokens = await getAPITokens()
        if (!api_tokens ||
             'detail' in api_tokens ||
              ( api_tokens.length > 0 && !('name' in api_tokens[0])) ) {
                showServerError()
            return
        }

        if (api_tokens !== null) {
            setApiTokens(api_tokens)
        }
        setLoading(false)
    }, [])

    const handleTokenCreate = async () => {
        const t:APIToken|undefined = apiTokens.find((tt:APIToken) => tt.name.toLowerCase() === newToken.name.toLowerCase())
        if (t) {
            setSnackBar({
                open: true,
                msg: 'Token with that name already exists.',
                variant: 'error',
                timeout: 3000,
            });
            return
        }

        setShowCreateToken(false)
        const res = await createAPIToken(newToken)
        if (!res || !('expiry' in res) || res.expiry === '') {
            showServerError()
            return
        }

        const now = new Date();
        newToken.created_at = now.toISOString();
        const updatedTokens = [...apiTokens, newToken]
        setApiTokens(updatedTokens)
        setEmptyNewToken()
        setSnackBar({
            open: true,
            msg: 'Token created successfully.',
            variant: 'success',
            timeout: 3000,
        });

        setTokenKeys(res)
    }

    const handleTokenDelete = async (t:APIToken) => {
        const res:SuccessResponse | null = await deleteAPIToken(t)
        if (!res || !('success' in res) || !res.success) {
            showServerError()
            return
        }

        const updatedTokens = apiTokens.filter((tt:APIToken) => tt.name !== t.name)
        setApiTokens(updatedTokens)
        setSnackBar({
            open: true,
            msg: 'Token delete successfully.',
            variant: 'success',
            timeout: 3000,
        });
    }

    const showDate = (str_date:string):string => {
        const dateObj = new Date(str_date);
        const month = dateObj.getUTCMonth() + 1; //months from 1-12
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        const months = ['Jan', 'Feb', "Mar", 'Apr', "May", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"]
        return months[month-1] + " " + day +", " + year;
    }

    const handleShowTokenKeysClose = (e, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        setShowTokenKeys(false)
    }

    const closeTokenKeys = () => {
        setShowTokenKeys(false)
    }

    useEffect(() => {
        if (tokenKeys.expiry === '') return;
        setShowTokenKeys(true)
    }, [tokenKeys])

    useEffect(() => {
        if (!showTokenKeys) {
            setTimeout(() => {
                setTokenKeys({
                    access_token: '',
                    refresh_token: '',
                    expiry: '',
                })
            }, 1000)
        }
    }, [showTokenKeys])

    return (
        <div className={props.classes.page}>
            <Dialog
                open={showTokenKeys}
                onClose={handleShowTokenKeysClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Tokens will be show only once!"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className={classes.keysWrapper}>
                        <div className={classes.keysTitle}>
                            access_token:
                        </div>
                        <div>
                            {tokenKeys.access_token}
                        </div>
                    </div>
                    <div className={classes.keysWrapper}>
                        <div className={classes.keysTitle}>
                            refresh_token:
                        </div>
                        <div>
                            {tokenKeys.refresh_token}
                        </div>
                    </div>
                    <div className={classes.keysWrapper}>
                        <div className={classes.keysTitle}>
                            expiry:
                        </div>
                        <div>
                            {tokenKeys.expiry}
                        </div>
                    </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={closeTokenKeys} variant="contained" className={props.classes.button}>
                    I copied keys
                </Button>
                </DialogActions>
            </Dialog>
            
            <DialogWrapper
                maxWidth={"sm"}
                open={showCreateToken}
                onClose={handleCreateTokenClose}
                title={"Create API Token"}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="token-name"
                        label="Name"
                        type="text"
                        fullWidth
                        inputProps={{ maxLength: 40 }}
                        onChange={(e)=>{
                            setNewToken({
                                ...newToken,
                                name: e.target.value
                            })
                        }}
                    />

                    <FormControl component="fieldset" className={classes.permissionsWrapper}>
                        <FormLabel component="legend">Permissions</FormLabel>
                        <FormGroup className={classes.permissions}>
                            <FormControlLabel
                                key={`tp-upload`}
                                control={
                                    <Checkbox
                                        disabled={app.user.upload === false}
                                        onChange={()=>{
                                            setNewToken({
                                                ...newToken,
                                                has_upload: !newToken.has_upload
                                            })
                                        }}
                                        name={"upload"}
                                        color="primary" />
                                }
                                label={"Upload"}
                            />
                            <FormControlLabel
                                key={`tp-download`}
                                control={
                                    <Checkbox
                                        disabled={app.user.download === false}
                                        onChange={()=>{
                                            setNewToken({
                                                ...newToken,
                                                has_download: !newToken.has_download
                                            })
                                        }}
                                        name={"download"}
                                        color="primary" />
                                }
                                label={"Download"}
                            />
                        </FormGroup>
                    </FormControl>

                <DialogActions>
                    <Button 
                    onClick={handleTokenCreate} 
                    variant="contained" 
                    className={props.classes.button}
                    disabled={ !((newToken.has_upload || newToken.has_download) && newToken.name.length > 2) }
                    >
                        Create
                    </Button>
                </DialogActions>

            </DialogWrapper>

            <NavBar />
            <Container component="main" maxWidth={"xl"} disableGutters className={classes.main}>
                <CssBaseline />

                <MySnackBar variant={snackBar.variant} message={snackBar.msg} open={snackBar.open} onClose={handleClose} />

                <Container maxWidth={"xl"}>
                    <Grid container spacing={3} className={props.classes.header}>
                        <Grid item xs={6}>
                            <Typography variant="h3" display={"inline"} className={`${props.classes.title}`}>
                                Token Management
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end" alignItems="center" direction="row" spacing={3}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    className={props.classes.button}
                                    startIcon={<AddOutlinedIcon />}
                                    onClick={() => {setShowCreateToken(true)}}
                                >
                                    Create Token
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Container>

                <Container maxWidth={"xl"}>
                     {/* No records found*/}
                     {loading ? <Grid item xs={12}>
                        <div className={classes.spinner}> 
                            <CircularProgress size={120} /> 
                        </div>
                        </Grid> : apiTokens.length === 0 ? <Grid container alignItems={"center"} justify={"center"}>
                            <Typography variant="h5" display={"inline"}>
                                No tokens found
                            </Typography>
                        </Grid> : null
                    }
                    <Grid container spacing={3}>
                        {apiTokens.map((t:APIToken) => 
                            <Grid item sm={12} md={6} xl={4}>
                                <Paper elevation={3}>
                                    <div className={classes.apiTokenWrapper}>
                                        <div className={classes.apiTokenIcon}>
                                            <VpnKeySharp />
                                        </div>
                                        <div className={classes.apiTokenInfoWrapper}>
                                            <div className={classes.apiTokenInfoName}>{t.name}</div>
                                            <div>
                                                <FormControlLabel
                                                    key={`tk-has-upload`}
                                                    control={
                                                        <Checkbox
                                                            checked={t.has_upload && app.user.upload}
                                                            disabled={true}
                                                            name={"has_upload"}
                                                            color="primary" />
                                                    }
                                                    label={"Upload"}
                                                />
                                                <FormControlLabel
                                                    key={`tk-has-download`}
                                                    control={
                                                        <Checkbox
                                                            checked={t.has_download && app.user.download}
                                                            disabled={true}
                                                            name={"has_download"}
                                                            color="primary" />
                                                    }
                                                    label={"Download"}
                                                />
                                            </div>
                                            {/* <div className={classes.apiTokenInfoDescription}>{t.description}</div> */}
                                            <div>Added on: {showDate(t.created_at)}</div>
                                        </div>
                                        <div className={classes.deleteButtonWrapper}>
                                            <Button
                                                variant="contained"
                                                className={classes.deleteButton}
                                                onClick={() => {handleTokenDelete(t)}}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                    
                                </Paper>
                            </Grid>
                        )}
                    </Grid>

                </Container>

            </Container>

        </div>
    );
}

const styles = theme => ({
    ...MyTypography(theme),
    ...Inputs(theme),
    ...Elements(),
});

// @ts-ignore
export default withStyles(styles)(Tokens)
