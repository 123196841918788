import {colors} from "./colors";

// transition: theme.transitions.create(
//     ['background-color', 'color'],
//     { duration: theme.transitions.duration.short }
// ),

export const inputs = theme => ({
    button: {
        fontWeight: 'bold',
        maxWidth: '200px',
        backgroundColor: colors.main,
        '&:hover': {
            backgroundColor: colors.selected,
            color: '#fff'
        }
    },
    buttonCancel: {
        fontWeight: 'bold',
        maxWidth: '200px',
        backgroundColor: colors.cancel,
        '&:hover': {
            backgroundColor: colors.selected,
            color: '#fff'
        }
    }
})
