import React from 'react';
import PropTypes from "prop-types";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {colors} from "../styles/colors";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    filterIcon: {
        fontSize: '30px',
        padding: '6px',
        marginBottom: '5px',
        backgroundColor: colors.main,
        '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12);',
        },
        '& svg path': {
            fill: 'black',
        },
        '&:hover': {
            backgroundColor: colors.selected,
            '& svg path': {
                fill: 'white',
            },
        }
    },
}))

export default function FilterIconButton(props:{label:string, disabled?:boolean, onClick:() => void, children:React.ReactNode}) {
    const theme = useTheme();
    const classes = useStyles(theme);
    return <IconButton
        aria-label={props.label}
        disabled={props.disabled ? props.disabled : false}
        onClick={props.onClick}
        className={classes.filterIcon}>
        {props.children}
    </IconButton>
}

FilterIconButton.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    // icon: PropTypes.element.isRequired,
    onClick: PropTypes.func.isRequired,
};
