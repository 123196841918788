import {useEffect, useRef} from "react";

// trigger the effect on first render only
export default function useDidMountEffect(effect, elements:any[]) {
    const didMountRef = useRef(true);

    useEffect(() => {
        if (didMountRef.current) {
            effect();
            didMountRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, elements);
}
