import {USER_API_TOKEN} from "../../constants/URLS";
import {APIToken} from "../../utils/types";

export const getAPITokens = ():Promise<APIToken[] | null> => {
    return fetch(
        USER_API_TOKEN,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(async resp => {
            const response:APIToken[] = await resp.json();
            return response
        })
        .catch(async error => {
            console.log(error)
            return null
        })
}
