import {USER_TOKEN_REFRESH} from "../../constants/URLS";
import {AppContextInterface, RefreshToken} from "../../utils/types";
import {logout} from "./logout";

const userLogout = async (app:AppContextInterface) => {
    await logout();
    app.setUser({
        name: '',
        email: '',
        token: {
            value: '',
            expiry: new Date(),
        },
        upload: false,
        download: false,
        support_email: '',
    })
    window.location.href = "/"
}

// send token refresh request to Django
export const refreshToken = (app:AppContextInterface) => {
    return fetch(
        USER_TOKEN_REFRESH,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(async resp => {
            const response:RefreshToken = await resp.json();
            if (response.token !== '') {
                app.setUser({
                    name: app.user.name,
                    email: app.user.email,
                    token: {
                        value: response.token,
                        expiry: new Date(response.expiry),
                    },
                    download: app.user.download,
                    upload: app.user.upload,
                    support_email: app.user.support_email,
                })
                return response.token
            }
            // user should be logged out
            await userLogout(app)
            return null
        })
        .catch(async error => {
            console.log(error)
            await userLogout(app)
            return null
        })
}
