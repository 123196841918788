import {FormControl, FormLabel} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {SearchCheckedItems} from "../utils/types";

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: 120,
        marginBottom: '15px',
    },
    formGroup: {
        flexDirection: 'row',
    },
    groupedCheckBox: {
        minWidth: '136px',
    },
}))

export default function SensorsSelector(props) {
    const classes = useStyles();
    const { sensors, values, selectOne } = props;

    const handleSensorChange = (event) => {
        let newValues:SearchCheckedItems = {...values};
        if (selectOne) {
            for (const k in newValues) {
                if (newValues.hasOwnProperty(k)) {
                    newValues[k] = false;
                }
            }
        }
        props.callback({...newValues, [event.target.name]: event.target.checked});
    };

    const loadSensorCheckboxes = ():JSX.Element[] => {
        let results:JSX.Element[] = [];
        sensors.forEach((sensor, i) => {
            results.push(<FormControlLabel
                className={classes.groupedCheckBox}
                key={`sens-${i}`}
                control={
                    <Checkbox
                        checked={values[sensor]}
                        onChange={handleSensorChange}
                        name={sensor}
                        color="primary"/>
                }
                label={sensor}
            />)
        })
        return results;
    }

    return <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Select Source</FormLabel>
        <FormGroup className={classes.formGroup}>
            {loadSensorCheckboxes()}
        </FormGroup>
    </FormControl>
}

SensorsSelector.propTypes = {
    sensors: PropTypes.array.isRequired,
    callback: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    selectOne: PropTypes.bool,
};
