import React, { useState} from 'react';
import {makeStyles, useTheme, withStyles} from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Typography as MyTypography, Elements, Inputs} from "../styles";
import NavBar from "../components/NavBar";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MySnackBar from "../components/Snackbar";

import {SnackbarMessage} from "../utils/types";
import {Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import {validateMetaData, validateTelemetry} from "../actions/validation/validation";

const useStyles = makeStyles(theme => ({
    main: {
        margin: '45px 0',
        [theme.breakpoints.up('md')]: {
            margin: '0'
        },
    },
    map: {
        padding: '4px'
    },
    appBar: {
        backgroundColor: 'white',
        color: 'black'
    },
    body: {
        // padding: '10px 40px'
    },
    textField: {
        width: '100%',
        '& textarea': {
            minHeight: '60vh',
            color: 'black'
        }
    },
    formControl: {
        minWidth: 120,
    },
    selector: {
        marginLeft: '5px',
        marginBottom: '15px'
    }
}))

const Validation = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [snackBar, setSnackBar] = useState<SnackbarMessage>({
        open: false,
        msg: '',
        variant: 'info',
        timeout: 3000,
    });
    const [schema, setSchema] = useState<String>("metadata")
    const [validationResponse, setValidationResponse] = useState<String>("")

    const handleClose = () => {
        setSnackBar({
            open: false,
            msg: '',
            variant: 'info',
            timeout: 3000,
        });
    }

    const handleChange = e => {
        setSchema(e.target.value)
    }

    const validateJSON = async () => {
        let response:string|null = "";
        let data = (document.getElementById('json-data') as HTMLInputElement).value;
        switch (schema) {
            case "metadata":
                response = await validateMetaData(data)
                break;
            case "telemetry":
                response = await validateTelemetry(data)
                break;
        }
        if (!response) {
            response = "";
        }
        setValidationResponse(response)
    }

    return (
        <div className={props.classes.page}>
            <NavBar />
            <Container component="main" maxWidth={"xl"} disableGutters className={classes.main}>
                <CssBaseline />

                <MySnackBar variant={snackBar.variant} message={snackBar.msg} open={snackBar.open} onClose={handleClose} />

                <Container maxWidth={"xl"} className={classes.body}>
                    <Grid container spacing={3} className={props.classes.header}>
                        <Grid item xs={6}>
                            <Typography variant="h3" display={"inline"} className={`${props.classes.title}`}>
                                JSON schema validation
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end" alignItems="center" direction="row" spacing={3}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    className={props.classes.button}
                                    startIcon={<DoneRoundedIcon />}
                                    onClick={validateJSON}
                                >
                                    Validate
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} className={classes.selector}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-helper-label">Schema</InputLabel>
                                <Select
                                    labelId="schema-select"
                                    id="validation-schema"
                                    value={schema}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"metadata"}>Meta data</MenuItem>
                                    <MenuItem value={"telemetry"}>Telemetry</MenuItem>
                                </Select>
                                <FormHelperText>Select validation schema to use</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className={classes.textField}
                                id="json-data"
                                label="JSON"
                                multiline
                                rows={4}
                                placeholder="JSON Data"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className={classes.textField}
                                id="validation-response"
                                label="Results"
                                multiline
                                rows={4}
                                disabled={true}
                                value={validationResponse}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>


                </Container>

            </Container>

        </div>
    );
}

const styles = theme => ({
    ...MyTypography(theme),
    ...Inputs(theme),
    ...Elements(),
});

// @ts-ignore
export default withStyles(styles)(Validation)
