import {META_DATA_UPLOAD, TELEMETRY_UPLOAD} from "../../constants/URLS";
import {UploadChunk, UploadChunkStatusEnum} from "../../utils/types";

const axios = require('axios').default;

export const uploadDataToCloud = (chunk:UploadChunk, token, updateChunk: (chunkToUpdate:UploadChunk) => void):void => {
    const formData = new FormData();
    chunk.files.forEach(file => {
        formData.append("file", file)
    })
    return axios.request({
        method: "POST",
        url: chunk.files.length === 1 ? TELEMETRY_UPLOAD : META_DATA_UPLOAD,
        data: formData,
        onUploadProgress: (p) => {
            chunk.progress = Math.round(p.loaded / p.total * 100);
            updateChunk(chunk);
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token,
        },
        }).then (resp => {
            chunk.progress = 100;
            if ( resp.status === 200 || resp.status === 201 ) {
                chunk.status = {
                    code: UploadChunkStatusEnum.UploadSuccess,
                    message: ""
                };
            } else {
                chunk.status = {
                    code: UploadChunkStatusEnum.UploadError,
                    message: JSON.stringify(resp.json())
                }
            }
            updateChunk(chunk);
        }).catch(error => {
            console.log(error)
            chunk.progress = 0;
            chunk.status = {
                code: UploadChunkStatusEnum.UploadError,
                message: JSON.stringify(error.response.data, null, 2)
            }
            updateChunk(chunk);
    })
}
