import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme, withStyles} from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Elements, Inputs, Typography as MyTypography} from "../styles";
import NavBar from "../components/NavBar";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {
    OriginalSearchSize,
    SearchFilterInfo, SearchLabelDataType,
    SearchResult,
    SearchResults,
    SearchResultsInfo,
    UnitSizes,
} from "../utils/types";
import {Button} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {AppContext} from "../App";
import CircularProgress from '@material-ui/core/CircularProgress';
import ShareIcon from '@material-ui/icons/Share';
import {search} from "../actions/search/search";
import {ConvertToUnit, FormatMetadataTimestamp, RefreshToken} from "../utils/helpers";
import {default as SearchResultItem} from '../components/SearchResult';
import FilterListIcon from '@material-ui/icons/FilterList';
import {colors} from "../styles/colors";
import SearchFilter from "../components/SearchFilter";
import Pagination from "../components/Pagination";
import GetApp from '@material-ui/icons/GetApp';
import {download} from "../actions/search/download";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FilterIconButton from "../components/FilterIconButton";

const useStyles = makeStyles(theme => ({
    main: {
        margin: '45px 0',
        [theme.breakpoints.up('md')]: {
            margin: '0'
        },
    },
    page: {
      display: 'flex',
    },
    header: {
        margin: '10px 0 20px 0',
        borderBottom: '2px solid #65bc7b',
        maxWidth: '100%',
    },
    spinner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        minWidth: '100%',
        position: 'fixed',
        top: '0',
        left: '140px',
        [theme.breakpoints.down('sm')]: {
            left: '0',
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        height: '95vh',
        overflow: 'hidden'
    },
    formControl: {
        minWidth: 120,
        marginBottom: '15px',
    },
    formGroup: {
        flexDirection: 'row',
    },
    searchResults: {
        maxHeight: 'calc(100vh - 180px)',
        overflowY: 'auto',
        border: '1px solid #ccc',
        margin: '0',
        maxWidth: '100%',
        // borderTopLeftRadius: '5px',
        // borderBottomLeftRadius: '5px',
    },
    pagination: {
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
    },
    downloadWrapper: {
        marginBottom: '5px'
    },
    searchInfoHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    downloadButton: {
        fontWeight: 'bold',
        maxWidth: '200px',
        maxHeight: '30px',
        marginLeft: '10px',
        backgroundColor: colors.main,
        '&:hover': {
            backgroundColor: colors.selected,
            color: '#fff'
        }
    },
    entriesFound: {
        margin: '15px 0 -15px 0',
    },
    totalEntries: {
        margin: '15px 0 -15px 0',
        fontSize: "0.9rem",
    }
}))

const Search = props => {
    const DOWNLOAD_LIMIT_GB:number = 1;
    const app = useContext(AppContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    const [loading, setLoading] = useState<boolean>(true);
    const [isDownloadLimit, setIsDownloadLimit] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [pagination, setPagination] = useState<number>(-1);
    const [searchResultsInfo, setSearchResultsInfo] = useState<SearchResultsInfo>(null as unknown as SearchResultsInfo);
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [originalResultsSize, setOriginalResultsSize] = useState<OriginalSearchSize>({
        file_size: 0,
        size_unit: UnitSizes.KB,
    });
    const [removedFileSize, setRemovedFileSize] = useState<number>(0);
    const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
    const [isNewSearch, setNewSearch] = useState<boolean>(false);
    const [isSearching, setSearching] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useState<string[][]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(true);
    const [searchFilterAction, setSearchFilterAction] = useState<SearchFilterInfo>(null as unknown as SearchFilterInfo)

    const clearSearch = ():void => {
        setSearchResults([]);
        setNewSearch(false);
        setSearching(true);
    }

    const setParamValue = (params:string[][], key:string, value:any):boolean => {
        let updated = false;
        params.forEach((param, i, arr) => {
            if (param[0] === key) {
                arr[i][1] = value;
                updated = true;
            }
        })
        return updated;
    }

    const handleSearch = async (updateSearchInfo?:boolean) => {

        let params:string[][] = [...searchParams];

        // check for empty sensors
        let sensors:string[]|undefined = params.find(fp => fp[0] === 'sensors');
        if (sensors && sensors[1] === '') {
            clearSearch()
            app.setSnackBar({
                open:true,
                msg:"No records found",
                variant: "info",
                timeout: 3000,
            });
            return
        }

        // refresh token if needed
        setLoading(true);
        let uploadToken = await RefreshToken(app)
        if (uploadToken === '') {
            setLoading(false);
            return
        }

        // clear previous results
        clearSearch()

        if (!setParamValue(params, 'pagination', String(pagination))) {
            params.push(['pagination', String(pagination)])
        }

        // get new records
        const sr:SearchResults = await search(app, params)
        if (updateSearchInfo || originalResultsSize.file_size !== sr.info.total_size) {
            if (originalResultsSize.file_size !== 0) {
                let mbSize = ConvertToUnit(sr.info.total_size, sr.info.size_unit, UnitSizes.MB);
                mbSize = mbSize - removedFileSize;
                sr.info.total_size = ConvertToUnit(mbSize, UnitSizes.MB, sr.info.size_unit);
                sr.info.file_size = Math.round(sr.info.total_size * 100) / 100;
            }
            // check download Limit
            let gbSize = ConvertToUnit(sr.info.total_size, sr.info.size_unit, UnitSizes.GB)
            setIsDownloadLimit(gbSize > DOWNLOAD_LIMIT_GB || gbSize === 0)
            setSearchResultsInfo(sr.info);
            setOriginalResultsSize({
                file_size: sr.info.total_size,
                size_unit: sr.info.size_unit,
            })
        }
        setSearchResults(sr.data);
        setLoading(false);
    }

    const handleDownload = async () => {
        setLoading(true);
        let uploadToken = await RefreshToken(app)
        if (uploadToken === '') {
            setLoading(false);
            return
        }

        let params:string[][] = [...searchParams];
        if (selectedIDs.length > 0) {
            let list = selectAll ? 'black_list' : 'white_list';
            if (!setParamValue(params, list, selectedIDs.join(','))) {
                params.push([list, selectedIDs.join(',')])
            }
        }

        await download(app, params)
        setLoading(false);
    }

    const updateDownloadSize = (newSize:number,
            isAdd:boolean, sri:SearchResultsInfo):number => {  
        // newSize comes from search record from DB and it always in MB  
        // get new changed size in MB
        const totalMBSize = ConvertToUnit(sri.total_size, sri.size_unit, UnitSizes.MB);
        const totalChangedMBSize = isAdd ? totalMBSize + newSize : totalMBSize - newSize;
        // convert MB to current size unit
        const newChangedSize = ConvertToUnit(totalChangedMBSize, UnitSizes.MB, searchResultsInfo.size_unit);
        sri.total_size = newChangedSize === 0 ? 0 : newChangedSize;

        // change unit size if necessary
        if (isAdd) {
            while (sri.total_size > 1000 && sri.size_unit !== UnitSizes.TB) {
                sri.size_unit += 1;
                sri.total_size = ConvertToUnit(sri.total_size, sri.size_unit - 1, sri.size_unit);
            }
        } else {
            while (sri.total_size < 1 && sri.size_unit !== UnitSizes.KB) {
                sri.size_unit -= 1;
                sri.total_size = ConvertToUnit(sri.total_size, sri.size_unit + 1, sri.size_unit);
            }
        }

        sri.file_size = Math.round(sri.total_size * 100) / 100;

        return totalChangedMBSize
    }

    const handleSearchItemCheck = (e, index:number) => {
        const tempSearchResults:SearchResult[] = [...searchResults];
        tempSearchResults[index].checked = e.target.checked;
        const tempSelectedIDs = [...selectedIDs];
        let data = tempSearchResults[index].data;
        let changedFilesSize = 0;
        data.files.forEach(f => {
            changedFilesSize += f.fileSize;
        })

        let isAdd = e.target.checked;
        const totalChangeFileSize = updateDownloadSize(changedFilesSize, isAdd, searchResultsInfo);

        // check download Limit
        let gbSize = ConvertToUnit(searchResultsInfo.total_size, searchResultsInfo.size_unit, UnitSizes.GB)
        setIsDownloadLimit(gbSize > DOWNLOAD_LIMIT_GB || gbSize === 0)

        if (selectAll) {
            if (!isAdd) {
                tempSelectedIDs.push(data._id);
            } else {
                tempSelectedIDs.splice(tempSelectedIDs.indexOf(data._id), 1);
            }
        } else {
            if (isAdd) {
                tempSelectedIDs.push(data._id);
            } else {
                tempSelectedIDs.splice(tempSelectedIDs.indexOf(data._id), 1);
            }
        }

        setRemovedFileSize(totalChangeFileSize);
        setSearchResults(tempSearchResults);
        setSelectedIDs(tempSelectedIDs);
    }

    const handleSearchFilterClose = (sfi:SearchFilterInfo|null) => {
        if (sfi) {
            setSearchFilterAction(sfi)
        }
        setShowFilter(false)
    }

    const newSearch = () => {
        setSearching(false);
        setNewSearch(true);
        setRemovedFileSize(0);
        setOriginalResultsSize({
            file_size: 0,
            size_unit: UnitSizes.KB,
        });
        setSelectedIDs([]);
        setPagination(-1);
        setSelectAll(true);
    }

    const newSearchClick = () => {
        let sfa:SearchFilterInfo = {
            params: (searchFilterAction && searchFilterAction.shared_query) ? [] : [...searchParams],
            shared_query: false,
            new_search: true,
        }
        setSearchFilterAction(sfa)
    }

    const handleSelectAll = () => {
        setSelectAll(!selectAll)
    }

    const copyLink = () => {
        const el = document.createElement('textarea');
        // el.style.display = 'none';
        el.value = document.location.origin + '/search?' + new URLSearchParams(searchParams).toString();
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        app.setSnackBar({
            open:true,
            msg:"Link copied",
            variant: "info",
            timeout: 3000,
        });
    }

    useEffect(() => {
        if (app.isLoading) return;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (Array.from(urlParams).length > 0) {
            let params:string[][] = [];
            urlParams.forEach((v, k) => {
                params.push([k, v])
            })
            let sfa:SearchFilterInfo = {
                params: params,
                new_search: true,
                shared_query: true,
            }
            setSearchFilterAction(sfa)
            // document.location.search = '';
            const uri = window.location.toString();
            if (uri.indexOf("?") > 0) {
                const clean_uri = uri.substring(0, uri.indexOf("?"));
                window.history.replaceState({}, document.title, clean_uri);
            }

        } else if (app.searchBaseStation) {
            let params:string[][] = [];
            if (app.searchBaseStation.type === "station") {
                params.push(['location', app.searchBaseStation.position.long + ',' + app.searchBaseStation.position.lat])
            } else {
                // for free-floating sensors add deviceID to query params
                params.push(['sensors', app.searchBaseStation.id]);
            }
            let sfi:SearchFilterInfo = {
                params: params,
                new_search: true,
                shared_query: true,
            }
            setSearchFilterAction(sfi)
            app.setSearchBaseStation(null)
        } else {
            handleSearch()
        }

    }, [app.isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isSearching) {
            handleSearch()
        }
    }, [pagination]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (searchFilterAction) { setSearchParams(searchFilterAction.params) }
    }, [searchFilterAction])

    useEffect(() => {
        if (searchFilterAction && searchFilterAction.new_search) { newSearch() }
    }, [searchParams]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // wait for all required stated to update
        if (!isSearching && isNewSearch && removedFileSize === 0 &&
            pagination === -1 && selectedIDs.length === 0 && originalResultsSize.file_size === 0 && selectAll ) {
            // start new search
            handleSearch(true);
        }
    }, [ // eslint-disable-line react-hooks/exhaustive-deps
        removedFileSize,
        pagination, selectedIDs,
        originalResultsSize, isNewSearch])

    useEffect(() => {
        setSelectedIDs([]);
        if (selectAll) {
            // select All mode
            searchResults.forEach(sr => { sr.checked = true })
            if (searchResultsInfo) {
                searchResultsInfo.total_size = originalResultsSize.file_size;
                searchResultsInfo.file_size = Math.round(searchResultsInfo.total_size * 100) / 100;
                searchResultsInfo.size_unit = originalResultsSize.size_unit;
                // check download Limit
                let gbSize = ConvertToUnit(searchResultsInfo.total_size, searchResultsInfo.size_unit, UnitSizes.GB);
                setIsDownloadLimit(gbSize > DOWNLOAD_LIMIT_GB || gbSize === 0);
            }
        } else {
            // select 1 by 1 mode
            searchResults.forEach(sr => { sr.checked = false })
            if (searchResultsInfo) {
                searchResultsInfo.total_size = 0;
                searchResultsInfo.file_size = 0;
                searchResultsInfo.size_unit = UnitSizes.KB;
            }
            setIsDownloadLimit(true)
        }
    }, [selectAll]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${props.classes.page} ${classes.page}`}>
            <NavBar />
            <Container component="main" maxWidth={"xl"} disableGutters className={classes.main}>
                <CssBaseline/>

                <SearchFilter
                    open={showFilter}
                    onClose={handleSearchFilterClose}
                />

                <Container maxWidth={"xl"} className={classes.body}>
                    <Grid container spacing={3} className={classes.header}>
                        <Grid item xs={2}>
                            <Typography variant="h3" display={"inline"} className={`${props.classes.title}`}>
                                Search
                            </Typography>
                        </Grid>
                        <Grid container
                              item xs={10}
                              justify="flex-end"
                              alignItems="center"
                              className={props.classes.noPadding}
                              spacing={3}>

                            <Grid item>
                                <FilterIconButton
                                    label={"filter"}
                                    onClick={copyLink}
                                    disabled={loading}
                                >
                                    <ShareIcon fontSize="inherit" />
                                </FilterIconButton>
                            </Grid>

                            <Grid item>
                                <FilterIconButton
                                    label={"filter"}
                                    onClick={() => {setShowFilter(!showFilter)}}
                                    disabled={loading}
                                >
                                    <FilterListIcon fontSize="inherit" />
                                </FilterIconButton>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    id="search-button"
                                    disabled={loading}
                                    className={`${props.classes.button}`}
                                    startIcon={<SearchIcon/>}
                                    onClick={newSearchClick}
                                >
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* No records found*/}
                    {searchResults.length === 0 ? loading ? null :
                        <Grid container alignItems={"center"} justify={"center"}>
                            <Typography variant="h5" display={"inline"}>
                                No records found
                            </Typography>
                        </Grid> : null
                    }

                    {/* Download and Pagination */}
                    {searchResults.length === 0 && pagination === -1 ? null :
                        <Grid container spacing={3} className={classes.downloadWrapper} alignItems={"center"}>
                            <Grid item xs={12} md={5} className={classes.searchInfoHeader}>
                                <Typography variant="h6" display={"inline"}>
                                    {searchResultsInfo.file_size} {UnitSizes[searchResultsInfo.size_unit]}
                                </Typography>
                                <Tooltip 
                                    title={isDownloadLimit ? `Download limit is: ${DOWNLOAD_LIMIT_GB}GB` : ''}
                                    arrow={true}
                                    placement='right'
                                >
                                    <span>
                                        <Button
                                            variant="contained"
                                            startIcon={<GetApp/>}
                                            className={classes.downloadButton}
                                            onClick={handleDownload}
                                            disabled={loading || isDownloadLimit}
                                        >
                                            Download
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid container item xs={12} md={7} className={classes.pagination} >
                                <Grid item>
                                    <Pagination
                                        disabled={loading}
                                        pages={searchResultsInfo.pages}
                                        pagination={pagination}
                                        setPagination={setPagination} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {searchResults.length === 0 && pagination === -1 ? null :
                        <Grid container spacing={0}>

                            <Grid item xs={12}>
                                <Typography variant="h6" display={"inline"} className={classes.entriesFound}>
                                    {searchFilterAction ? "Found" : "Showing"} {searchResultsInfo.count} entries
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" display={"inline"} className={classes.totalEntries}>
                                    Total entries {searchResultsInfo.total_records}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={loading}
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            name="selectAll"
                                            color="primary"
                                        />
                                    }
                                    label={selectAll ? "Deselect All" : "Select All"}
                                />
                            </Grid>
                        </Grid>
                    }

                    {/* Search Results */}
                    <Grid container spacing={3} className={searchResults.length > 0 ? classes.searchResults : ''}>
                        <Grid item xs={12}>
                            {!loading ? null : <div className={classes.spinner}>
                                <CircularProgress size={120} /> </div>}
                            {searchResults.map((sr, i) => {
                                let sensorName = '';
                                let label:SearchLabelDataType=SearchLabelDataType.Raw;
                                let isResearchUsable:boolean = sr.data.usableForResearchPurposes;
                                app.baseStations.forEach(bs => {
                                    let s = bs.sensors.find(s => +s.id === +sr.data.deviceID)
                                    if (s) {
                                       sensorName = s.type.charAt(0).toUpperCase() + s.type.slice(1);
                                       if (sr.data.sourceFiles.length > 0) {
                                           label = SearchLabelDataType.Processed;
                                       }
                                    }
                                });
                                const startTS = FormatMetadataTimestamp(sr.data.timestamp.start);
                                const stopTS = FormatMetadataTimestamp(sr.data.timestamp.stop);
                                let tsToShow = startTS;
                                if (startTS !== stopTS) {
                                    tsToShow = tsToShow + ' - ' + stopTS;
                                }
                                if (!selectAll) {
                                    sr.checked = false;
                                }
                                if (selectedIDs.indexOf(sr.data._id) !== -1 ) {
                                    sr.checked = !selectAll;
                                }
                                return <SearchResultItem
                                    key={`sri-${i}`}
                                    disabled={loading}
                                    checked={sr.checked}
                                    onChange={(e)=>{handleSearchItemCheck(e, i)}}
                                    header={sensorName}
                                    label={label}
                                    isResearchUsable={isResearchUsable}
                                    subHeader={tsToShow}
                                    data={sr.data}
                                />
                            })}
                        </Grid>
                    </Grid>

                </Container>
            </Container>
        </div>
    );
}

const styles = theme => ({
    ...MyTypography(theme),
    ...Inputs(theme),
    ...Elements(),
});

// @ts-ignore
export default withStyles(styles)(Search)
