import React, { useContext, useState } from 'react';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Elements, Inputs, Typography as MyTypography } from "../styles";
import NavBar from "../components/NavBar";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { UploadChunk, UploadChunkStatusEnum } from "../utils/types";
import DropZone from "../components/DropZone";
import { Button, Paper, TextField } from "@material-ui/core";
import CloudUpload from '@material-ui/icons/CloudUpload';
import { uploadDataToCloud } from "../actions/upload/uploadDataToCloud";
import { AppContext } from "../App";
import { colors } from "../styles/colors";
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import { RefreshToken } from "../utils/helpers";


const useStyles = makeStyles(theme => ({
    main: {
        margin: '45px 0',
        [theme.breakpoints.up('md')]: {
            margin: '0'
        },
    },
    body: {
    },
    chunksWrapper: {
        paddingBottom: '10px',
    },
    chunkWrapper: {
        padding: '5px 10px',
    },
    chunkNameWrapper: {
        maxHeight: '35px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '2px solid #ccc',
        borderRadius: '4px',
        padding: '5px',
        cursor: 'pointer',
        fontWeight: 'bold',
        // '&:hover': {
        //     borderColor: colors.navSelected,
        // }
        '&:hover': {
            backgroundColor: colors.navSelected,
            color: 'white',
            '& .arrow': {
                border: 'solid white',
                borderWidth: '0 3px 3px 0',
            },
            '& $uploadProgress': {
                '& svg circle': {
                    color: 'white',
                }
            }
        },

    },
    chunkNameWrapperOpened: {
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
    },
    arrowNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '80%',
        zIndex: 10,
    },
    chunkName: {
        margin: '3px 0 -2px 10px',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '10px',
    },
    chunkStatus: {
        marginTop: '4px',
        position: 'relative',
        zIndex: 10,
    },
    statusOK: {

    },
    statusMissingFiles: {
        backgroundColor: '#f74040',
        color: 'white',
        // '&:hover': {
        //     backgroundColor: '#f74040',
        //     color: 'white'
        // },
        '& .arrow': {
            border: 'solid white',
            borderWidth: '0 3px 3px 0',
        }
    },
    statusUploadSuccess: {
        backgroundColor: '#438f56',
        color: 'white',
        '& .arrow': {
            border: 'solid white',
            borderWidth: '0 3px 3px 0',
        }
    },
    statusUploadError: {
        backgroundColor: '#f74040',
        color: 'white',
        '& .arrow': {
            border: 'solid white',
            borderWidth: '0 3px 3px 0',
        }
    },
    uploadFile: {
    },
    dot: {
        backgroundColor: 'black',
        borderRadius: '50%'
    },
    filesListShow: {
        border: '1px solid #ccc',
        padding: '5px 15px',
        display: 'block'
    },
    filesListHide: {
        display: 'none'
    },
    subHeader: {
        display: 'block',
        padding: '5px 0 5px 10px',
        textAlign: 'left',
        fontSize: '1.2rem',
        borderBottom: '1px solid #ccc',
        marginBottom: '10px',
    },
    statusReady: {
        // backgroundColor: '#65bc7b',
        // color: 'white'
    },
    unmatchedFileWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px'
    },
    unmatchedFile: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // textAlign: 'center',
    },
    progressBar: {
        position: 'absolute',
        left: '0',
        width: '100%',
        height: '100%',
        top: '0',
        zIndex: 0,
        borderRadius: '2px',
    },
    chunkResponse: {
        marginTop: '10px',
        width: '100%',
        '& label': {
            color: 'black !important',
        },
        '& textarea': {
            color: 'black',
        }
    },
    paper: {
        margin: '0 0 20px 0',
    }
}))

const ColorLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: '#fff0',
    },
    barColorPrimary: {
        backgroundColor: '#3899df',
    },
})(LinearProgress);

const Upload = props => {
    const app = useContext(AppContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    const maxSimultaneousUploads: number = 10;
    const [uploadChunks, setUploadChunks] = useState<UploadChunk[]>([]);
    const [isUploading, setUploading] = useState<boolean>(false);
    const [unmatchedFiles, setUnmatchedFiles] = useState<string[]>([]);
    const onDrop = (uploadChunks: UploadChunk[], unmatchedFiles: string[]) => {
        setUploadChunks(uploadChunks)
        setUnmatchedFiles(unmatchedFiles)
    }

    const showError = () => {
        app.setSnackBar({
            open: true,
            msg: "No correct metadata/telemetry (JSON) found.",
            variant: "error",
            timeout: 3000,
        });
    }

    const inProgress = () => {
        app.setSnackBar({
            open: true,
            msg: "Upload in progres...",
            variant: "info",
            timeout: 3000,
        });
    }

    const toggleFiles = idNum => {
        const fileList = document.getElementById('fileList' + idNum);
        if (fileList) {
            fileList.classList.toggle(classes.filesListShow)
            fileList.classList.toggle(classes.filesListHide)
        }
        const arrow = document.getElementById('arrow' + idNum);
        if (arrow) {
            arrow.classList.toggle('arrow--right')
            arrow.classList.toggle('arrow--down')
        }
        const chunkWrapper = document.getElementById('chunkWrapper' + idNum);
        if (chunkWrapper) {
            chunkWrapper.classList.toggle(classes.chunkNameWrapperOpened)
        }
    }

    const uploadData = async () => {
        // do nothing if nothing to upload
        if (uploadChunks.length === 0) {
            app.setSnackBar({
                open: true,
                msg: "Nothing to upload.",
                variant: "info",
                timeout: 3000,
            });
            return;
        }

        // refresh token before upload start to make sure user is still logged in
        const tokenTest = await RefreshToken(app, true)
        if (tokenTest === '') return;

        setUploading(true);
        const allChunks: UploadChunk[] = [...uploadChunks.filter(c => c.status.code === UploadChunkStatusEnum.OK)];
        while (allChunks.length > 0) {
            // get next chunks to upload
            const chunksToUpload: UploadChunk[] = [];
            for (let i = 0; i < maxSimultaneousUploads; i++) {
                const chunk: UploadChunk | undefined = allChunks.shift();
                if (chunk) {
                    chunksToUpload.push(chunk)
                }
            }
            // refresh token
            let uploadToken = await RefreshToken(app, true)
            if (uploadToken === '') {
                chunksToUpload.forEach(chunk => {
                    chunk.status = {
                        code: UploadChunkStatusEnum.UploadError,
                        message: "Unauthorized"
                    }
                    updateChunk(chunk);
                })
                return
            }

            await Promise.all(chunksToUpload.map(async c => {
                c.status.code = UploadChunkStatusEnum.Uploading;
                updateChunk(c)
                await uploadDataToCloud(c, uploadToken, updateChunk)
            }))
        }
        setUploading(false);
    }

    const updateChunk = (chunkToUpdate: UploadChunk) => {
        const updatedChunks = uploadChunks.map(chunk => {
            if (chunk.name === chunkToUpdate.name) {
                chunk.progress = chunkToUpdate.progress;
                chunk.status = chunkToUpdate.status;
            }
            return chunk;
        })
        setUploadChunks(updatedChunks);
    }
    const setStatus = (chunk: UploadChunk): string => {
        let status: string = '';
        switch (chunk.status.code) {
            case UploadChunkStatusEnum.Uploading:
                status = chunk.progress > 99 ? "99%" : chunk.progress + "%";
                // status = "...uploading";
                break;
            case UploadChunkStatusEnum.MissingFiles:
                status = "error";
                break;
            case UploadChunkStatusEnum.OK:
                status = "ready for upload";
                break;
            case UploadChunkStatusEnum.UploadError:
                status = "error";
                break;
            case UploadChunkStatusEnum.UploadSuccess:
                status = "uploaded";
                break;
            case UploadChunkStatusEnum.SyntaxError:
                status = "error";
                break;
            case UploadChunkStatusEnum.MultipleJsonFileNameError:
                status = "error";
                break;
        }
        return status;
    }

    const setStatusClass = (chunk: UploadChunk): string => {
        let cl = '';
        switch (chunk.status.code) {
            case UploadChunkStatusEnum.Uploading:
                cl = "";
                break;
            case UploadChunkStatusEnum.MissingFiles:
                cl = classes.statusMissingFiles;
                break;
            case UploadChunkStatusEnum.OK:
                cl = classes.statusOK;
                break;
            case UploadChunkStatusEnum.UploadError:
                cl = classes.statusUploadError;
                break;
            case UploadChunkStatusEnum.UploadSuccess:
                cl = classes.statusUploadSuccess;
                break;
            case UploadChunkStatusEnum.SyntaxError:
                cl = classes.statusMissingFiles;
                break;
            case UploadChunkStatusEnum.MultipleJsonFileNameError:
                cl = classes.statusMissingFiles;
                break;
        }
        return cl;
    }

    return (
        <div className={props.classes.page}>
            <NavBar />
            <Container component="main" maxWidth={"xl"} disableGutters className={classes.main}>
                <CssBaseline />

                <Container maxWidth={"xl"} className={classes.body}>
                    <Grid container spacing={3} className={props.classes.header}>
                        <Grid item xs={6}>
                            <Typography variant="h3" display={"inline"} className={`${props.classes.title}`}>
                                Upload data
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end" alignItems="center" direction="row" spacing={3}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    disabled={isUploading}
                                    className={props.classes.button}
                                    startIcon={<CloudUpload />}
                                    onClick={uploadData}
                                >
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignContent="center" justify="center">
                        <Grid item xs={3} sm={3} md={2} lg={3} />
                        <Grid item xs={12} sm={12} md={8} lg={6} >
                            <DropZone
                                inProgress={inProgress}
                                noMatch={showError}
                                onDrop={onDrop}
                                isUploading={isUploading}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={2} lg={3} />
                        <Grid item xs={12} sm={12} md={8} lg={6} className={
                            (uploadChunks.length > 0) ? '' : 'ds-none'}>
                            <Paper className={classes.paper}>
                                {/*  START Upload Chunks  */}
                                <Typography variant="h4" display={"inline"} className={`${classes.subHeader}`}>
                                    Prepared for upload
                                </Typography>
                                <div className={classes.chunksWrapper} >
                                    {uploadChunks.map((uc, i) =>
                                        <div className={classes.chunkWrapper} key={`ucw-${i}`} >
                                            <div className={`${classes.chunkNameWrapper}
                                            ${setStatusClass(uc)}`}
                                                id={`chunkWrapper${i}`}
                                                onClick={() => toggleFiles(i)} >
                                                <div className={classes.arrowNameWrapper}>
                                                    <div id={`arrow${i}`} className={`arrow arrow--right`} />
                                                    <div className={classes.chunkName}>
                                                        {uc.name}
                                                    </div>
                                                </div>
                                                {!isUploading ?
                                                    <div className={classes.chunkStatus}>
                                                        <div className="statusText">
                                                            {setStatus(uc)}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className={classes.chunkStatus}>
                                                            <div className="statusText">
                                                                {setStatus(uc)}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <ColorLinearProgress
                                                                className={classes.progressBar}
                                                                variant="determinate"
                                                                value={uc.progress} />
                                                        </div>
                                                    </div>
                                                }

                                            </div>

                                            {/* Upload Chunk Dropdown text */}
                                            <div className={classes.filesListHide} id={`fileList${i}`}>
                                                {(uc.status.code === UploadChunkStatusEnum.MissingFiles ||
                                                    uc.status.code === UploadChunkStatusEnum.SyntaxError ||
                                                    uc.status.code === UploadChunkStatusEnum.MultipleJsonFileNameError) ?
                                                    <div>
                                                        {uc.status.message}
                                                    </div>
                                                    :
                                                    uc.status.code === UploadChunkStatusEnum.UploadError ?
                                                        <TextField
                                                            className={classes.chunkResponse}
                                                            id="validation-response"
                                                            label="Response"
                                                            multiline
                                                            rows={12}
                                                            disabled={true}
                                                            value={uc.status.message}
                                                            variant="outlined"
                                                        />
                                                        :
                                                        uc.files.length === 1 ?
                                                            <div>
                                                                Telemetry file.
                                                            </div>
                                                            :
                                                            <div>
                                                                {uc.files.map((f, fi) => {
                                                                    if (uc.metadataFileName === f.name) return null;
                                                                    return <div key={`uc-${fi}`}
                                                                        className={classes.uploadFile}>
                                                                        - {f.name}
                                                                    </div>
                                                                })}
                                                            </div>
                                                }

                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/*  END Upload Chunks  */}
                            </Paper>

                            {unmatchedFiles.length === 0 ? null :
                                <Paper className={classes.paper}>
                                    {/*  START Unmatched Files  */}
                                    {unmatchedFiles.length === 0 ? null :
                                        <div>
                                            <Typography variant="h4" display={"inline"}
                                                className={`${classes.subHeader}`}>
                                                Files that don't belong to any meta data (will not be uploaded)
                                            </Typography>
                                            <div className={classes.unmatchedFileWrapper}>
                                                {unmatchedFiles.map((uf, i) =>
                                                    <div className={classes.unmatchedFile} key={`umf-${i}`}>
                                                        {i + 1 + '. ' + uf}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </Paper>
                            }
                        </Grid>
                    </Grid>


                </Container>

            </Container>

        </div>
    );
}

const styles = theme => ({
    ...MyTypography(theme),
    ...Inputs(theme),
    ...Elements(),
});

// @ts-ignore
export default withStyles(styles)(Upload)
