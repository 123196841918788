import React, {useContext} from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import {AppContext} from "../App";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const app = useContext(AppContext);
    return (
        <Route {...rest} render={
            props => {
                if (app.isLoading) {
                    return <Component {...rest} {...props} />
                }
                if (rest.path === '/tokens' && (app.user.download || app.user.upload || process.env.NODE_ENV === 'development') ) {
                    return <Component {...rest} {...props} />
                }
                if (rest.path === '/search' && (app.user.download || process.env.NODE_ENV === 'development')) {
                    return <Component {...rest} {...props} />
                }
                if (rest.path === '/upload' && (app.user.upload || process.env.NODE_ENV === 'development') ) {
                    return <Component {...rest} {...props} />
                }
                return <Redirect to="/" />;
            }
        } />
    )
}

export default ProtectedRoute;
