export const typography = theme => ({
    title: {
        textAlign: 'center',
        fontSize: '2rem',
        color: '#000',
    },
    title2: {
        textAlign: 'center',
        fontSize: '1.5rem',
        color: '#000',
    },
    title3: {
        textAlign: 'center',
        fontSize: '1rem',
        color: '#000',
    },
    header: {
        margin: '10px 0 20px 0',
        borderBottom: '2px solid #65bc7b',
        maxWidth: '100%',
        minHeight: '62px'
    },
})
