import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import MyChart from "./Chart";
import {Paper} from "@material-ui/core";
import Grid from '@material-ui/core/Grid/Grid';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';
import {colors} from "../styles/colors";
import {BaseStationInterface, ChartData, ChartType, TelemetryReply, WidgetInfo} from "../utils/types";
import {getTelemetry} from "../actions/search/telemetry";
import {FormatDateToDay} from "../utils/helpers";
import {AppContext} from "../App";
import Spinner from "./Spinner";
import useDidUpdateEffect from "../CustomHooks/useDidUpdateEffect";

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'left',
        fontSize: '1.5rem',
        color: '#000',
        width: '100%',
        overflow: 'hidden',
        whiteSpace:'nowrap',
        textOverflow: 'ellipsis',
    },
    chartCard: {
        // padding: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '330px',
        paddingBottom: '20px'
    },
    header: {
        padding: '5px 0 0 15px',
        borderBottom: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    edit: {
        '& svg path': {
            // fill: 'red'
        },
        '&:hover': {
            backgroundColor: 'white'
        },
        '&:hover svg path': {
            fill: colors.main,
        }
    },
    remove: {
        '& svg path': {
            // fill: 'red'
        },
        '&:hover': {
          backgroundColor: 'white'
        },
        '&:hover svg path': {
            fill: 'red'
        }
    },
    spinner: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        minWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            left: '0',
        },
    },
    nodata: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        minWidth: '100%',
        fontWeight: 'bold',
        fontSize: '1.5em',
        color: '#bbb',
        [theme.breakpoints.down('sm')]: {
            left: '0',
        },
    },
}));

export default function Widget(props:{widgetInfo:WidgetInfo, index:number,
    onEdit:(i:number)=>void, onDelete:(i:number)=>void}) {
    const classes = useStyles();
    const app = useContext(AppContext);
    const { widgetInfo, index } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
    const [data, setData] = useState<ChartData>({
        labels: [],
        datasets: []
    })

    const loadData = async () => {
        // if any locations is selected, then update deviceIDs
        if (widgetInfo.locations.length === 0) {
            let newParams:string[][] = [];
            widgetInfo.params.forEach(p => {
                if (p[0] !== 'location') {
                    newParams.push(p)
                }
            })

            widgetInfo.params = newParams;
            app.baseStations.forEach((bs: BaseStationInterface) => {
                widgetInfo.params.push(['location', bs.position.long + ',' + bs.position.lat]);
            })
        }

        // fix null keys
        let newParams:string[][] = [];
        widgetInfo.params.forEach(p => {
            if (p[0] !== 'key' && (!p[1] || p[1] === 'null' || p[1] === 'undefined' ) ) {
                return
            }

            if (p[0] !== 'labelKey' && (!p[1] || p[1] === 'null' || p[1] === 'undefined' ) ) {
                return
            }

            newParams.push(p)
        })
        widgetInfo.params = newParams;
       
        let response:TelemetryReply = await getTelemetry(widgetInfo.title, widgetInfo.params, app.setSnackBar, app.baseStations)

        const colors = {
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(201, 203, 207, 0.2)'
            ],
            borderColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 159, 64)',
                'rgb(153, 102, 255)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(201, 203, 207)'
            ],
        }

        let newData = {...data};
        let i = 0;
        for (const k in response) {
            newData.datasets.push({
                fill: false,
                label: k, // convert to base station
                data: response[k],
                borderColor: colors.borderColor[i],
                backgroundColor: widgetInfo.type === ChartType.Line ? colors.borderColor[i]
                    : colors.backgroundColor[i],
                borderWidth: 1,
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThickness: 8,
                minBarLength: 2,
            })
            i++;
        }

        setData(newData);
        setDataLoaded(true);
    }

    const updateCallback = () => {
        setShouldUpdate(false)
    }

    useEffect(() => {
       if (widgetInfo) {
           setData({
               labels: [],
               datasets: []
           })
           setShouldUpdate(true);
           setLoading(false)
           setDataLoaded(false);
       }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetInfo])

    useEffect(() => {
        if (shouldUpdate && data.datasets.length === 0 && !loading && !dataLoaded) {
            setLoading(true)
            loadData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldUpdate, data, loading])

    useDidUpdateEffect(() => {
        if (dataLoaded) {
            setLoading(false)
            setShouldUpdate(true)
        }
    }, [data, dataLoaded])

    return (
        <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper elevation={1} className={classes.chartCard}>
                <div className={classes.header}>
                    <Typography variant="h5" display={"inline"} className={classes.title}>
                        {widgetInfo.title}
                    </Typography>
                    <IconButton aria-label="edit" className={classes.edit} onClick={()=>{props.onEdit(index)}}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" className={classes.remove} onClick={()=>{props.onDelete(index)}}>
                        <CancelIcon />
                    </IconButton>
                </div>

                {loading ? <Spinner className={classes.spinner} size={80} /> :
                    data.datasets.length === 0 ? <div className={classes.nodata}>No records found</div> :
                    <MyChart
                        type={ChartType[widgetInfo.type].toLowerCase()}
                        data={data}
                        shouldUpdate={shouldUpdate}
                        updateCallback={updateCallback}
                        options={{
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            return context.dataset.data[context.dataIndex].label+ ': '+ context.parsed.y;
                                        }
                                    }
                                },
                                title: {
                                    display: true,
                                    text: widgetInfo.sensorName + ' "' + widgetInfo.fullKey + '" ' +
                                        FormatDateToDay(widgetInfo.startDate) + ' - ' +
                                        FormatDateToDay(widgetInfo.stopDate),
                                },
                            },
                            scales: {
                                x: {
                                    type: 'time',
                                    time: {
                                        tooltipFormat: 'DD.MM.YY HH:mm:ss'
                                    },
                                },
                            }}
                        }
                    />
                }
            </Paper>
        </Grid>
    );
}

Widget.propTypes = {
    widgetInfo: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    // data: PropTypes.array.isRequired,
};
