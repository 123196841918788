import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Typography as MyTypography, Elements, Inputs } from "../styles";
import NavBar from "../components/NavBar";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import AddBoxRounded from "@material-ui/icons/AddBoxRounded";
import { WidgetInfo } from "../utils/types";
import TelemetryFilter from "../components/TelemetryFilter";
import Widget from '../components/Widget';
import { AppContext } from "../App";
import Spinner from "../components/Spinner";

const defaultWidgets = "[{\"title\":\"CPU Temperature\",\"sensorName\":\"Bioacoustic\"," +
    "\"startTime\":\"2021-05-31T22:00:00.000Z\",\"stopTime\":\"2021-07-01T19:40:00.000Z\"," +
    "\"sensors\":[\"Bioacoustic\"],\"locations\":[],\"fullKey\":\"computingUnit.cpuTemperatur\"," +
    "\"key\":\"cpuTemperatur\",\"type\":0,\"customSearch\":[],\"params\":[[\"location\",\"11.6055,48.16431\"]" +
    ",[\"location\",\"7.09026,50.712845\"],[\"location\",\"13.8333,52.87826\"],[\"sensors\",\"8210,8171,8278,8173\"]" +
    ",[\"key\",\"computingUnit.cpuTemperatur\"],[\"startTime\",\"2021-05-31T22:00:00.000Z\"]" +
    ",[\"stopTime\",\"2021-07-01T19:40:00.000Z\"]]}]"

const useStyles = makeStyles(theme => ({
    main: {
        margin: '45px 0',
        [theme.breakpoints.up('md')]: {
            margin: '0'
        },
    },
    spinner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        minWidth: '100%',
        position: 'fixed',
        top: '0',
        left: '140px',
        [theme.breakpoints.down('sm')]: {
            left: '0',
        },
    },
}))

const Dashboard = props => {
    const theme = useTheme();
    const app = useContext(AppContext);
    const classes = useStyles(theme);
    const [loading, setLoading] = useState<boolean>(true);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [widgets, setWidgets] = useState<WidgetInfo[]>([]);
    const [selectedWidget, setSelectedWidget] = useState<number>(-1)

    const handleSearchFilterClose = () => {
        setShowFilter(false);
        setSelectedWidget(-1);
    }

    const handleSearchFilterAction = (wi: WidgetInfo) => {
        if (selectedWidget !== -1) {
            // update widget
            widgets[selectedWidget] = wi;
            localStorage.setItem('ammod_widgets', JSON.stringify(widgets));
            setSelectedWidget(-1);
            setShowFilter(false);
            return
        }

        let w = [...widgets];
        w.push(wi)
        localStorage.setItem('ammod_widgets', JSON.stringify(w));
        setWidgets(w)
        setShowFilter(false);
    }

    const handleEdit = (index: number) => {
        setSelectedWidget(index);
    }

    const handleRemove = (index: number) => {
        let w: WidgetInfo[] = [...widgets];
        w.splice(index, 1)
        localStorage.setItem('ammod_widgets', JSON.stringify(w));
        setWidgets(w)
    }

    const loadWidgets = (widgets: string) => {
        let tempWidgets:WidgetInfo[] = JSON.parse(widgets);
        tempWidgets.forEach( (wid:WidgetInfo) => {
            wid.startDate = new Date(wid.startDate);
            wid.stopDate = new Date(wid.stopDate);
            if (!wid.labelKey || wid.labelKey === "undefined" || wid.labelKey === "null") {
                wid.labelKey = ''
            }
        })
        setWidgets(tempWidgets)
    }

    useEffect(() => {
        if (app.isLoading) return;
        const tempWidgets: string | null = localStorage.getItem('ammod_widgets');
        if (!tempWidgets || tempWidgets === "[]" || tempWidgets === "") {
            if (!tempWidgets) {
                // load default widgets
                loadWidgets(defaultWidgets)
            } else {
                setLoading(false)
            }
        } else {
            loadWidgets(tempWidgets)
        }
        // setLoading(false)
    }, [app.isLoading])

    useEffect(() => {
        if (loading && widgets.length > 0) {
            setLoading(false)
        }
    }, [widgets, loading])

    useEffect(() => {
        if (selectedWidget !== -1) { setShowFilter(true) }
    }, [selectedWidget])

    return (
        <div className={props.classes.page}>
            <NavBar />
            <Container component="main" maxWidth={"xl"} disableGutters className={classes.main}>
                <CssBaseline />

                <TelemetryFilter
                    open={showFilter}
                    widget={widgets[selectedWidget]}
                    onClose={handleSearchFilterClose}
                    onSuccess={handleSearchFilterAction}
                    forceLocation={true}
                />

                <Container maxWidth={"xl"}>
                    <Grid container spacing={3} className={props.classes.header}>
                        <Grid item xs={2}>
                            <Typography variant="h3" display={"inline"} className={`${props.classes.title}`}>
                                Dashboard
                            </Typography>
                        </Grid>
                        <Grid container
                            item xs={10}
                            justify="flex-end"
                            alignItems="center"
                            className={props.classes.noPadding}
                            spacing={3}>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    id="search-button"
                                    disabled={loading}
                                    className={`${props.classes.button}`}
                                    startIcon={<AddBoxRounded />}
                                    onClick={() => { setShowFilter(true) }}
                                >
                                    Add Widget
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {app.isLoading ? <Spinner className={classes.spinner} size={120} /> :
                        <Grid container spacing={3}>
                            {widgets.length !== 0 ? null : <div className="inner-msg">
                                No widgets
                            </div>}
                            {widgets.map((wi, i) => <Widget
                                key={`widget-${i}`}
                                index={i}
                                widgetInfo={wi}
                                onEdit={handleEdit}
                                onDelete={handleRemove}
                            />)}
                        </Grid>
                    }


                </Container>

            </Container>

        </div>
    );
}

const styles = theme => ({
    ...MyTypography(theme),
    ...Inputs(theme),
    ...Elements(),
});

// @ts-ignore
export default withStyles(styles)(Dashboard)
