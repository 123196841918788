import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles, useTheme, withStyles} from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Typography as MyTypography, Elements, Inputs} from "../styles";
import Leaflet from "../components/MapChart";
import NavBar from "../components/NavBar";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {Button, Tooltip} from "@material-ui/core";
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import {AppContext, FilterContext} from "../App";
import {BaseStationInterface, HomeFilter, Legend} from "../utils/types";
import MapIcon from '@material-ui/icons/MapOutlined';
import ViewComfy from '@material-ui/icons/ViewComfy';
import ClearOutlinedIcon from '@material-ui/icons/CloseOutlined';
import HomeFilterTable from "../components/HomeFilterTable/HomeFilterTable";

const useStyles = makeStyles(theme => ({
    main: {
        margin: '45px 0',
        [theme.breakpoints.up('md')]: {
            margin: '0'
        },
    },
    map: {
        padding: '4px'
    },
    appBar: {
        backgroundColor: 'white',
        color: 'black'
    },
    header: {
        margin: '10px 0 10px 0',
        borderBottom: '2px solid #65bc7b',
        maxWidth: '100%',
        minHeight: '62px'
    },
    recenter: {
        color: theme.palette.primary.contrastText
    },
    legend: {
        display: 'flex',
        flex: '1',
        flexWrap: 'wrap',
        marginBottom: '15px',
        fontSize: '0.85rem',
        fontWeight: 'bold',
        '&>:nth-child(3)': {
            margin: '10px 10px 10px 0'
        }
    },
    legendRow: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            maxHeight: '32px',
        },
        '& div': {
            fontWeight: 'bold',
            paddingLeft: '10px'
        },
        paddingRight: '15px'
    },
    sensorWrapper: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        margin: '10px',
        // minWidth: '135px'
    },
    sensorImg: {
        maxWidth: '24px',
        minWidth: '24px',
        marginRight: '10px'
    },
    sensorImgWind: {
        maxWidth: '28px',
        minWidth: '28px',
        marginRight: '10px'
    },
    tableToolbar: {
        marginBottom: '15px',
        '& button': {
            maxHeight: '26px',
            marginRight: '20px',
            fontSize: '0.8rem',
            textTransform: 'none'
        }
    },
    resetFilter: {
        marginLeft: '15px',
        paddingTop: '10px',
        '& button': {
            maxHeight: '25px',
            textTransform: 'none',
        }
    },
    tableIcon: {
        maxHeight: '18px',
        minHeight: '18px'
    },
    tooltip: {
        fontSize: '1.2em',
        fontWeight: 300,
        lineHeight: '1em',
        '& span': {
            display: 'flex',
            alignItems: 'center',
        },
    },
}))

const Home = props => {
    const app = useContext(AppContext);
    const mapRef = useRef();
    const theme = useTheme();
    const classes = useStyles(theme);
    const [legend, setLegend] = useState<Legend>({
        activeBaseStations: 0,
        inactiveBaseStations: 0,
        bioSensors: 0,
        cameraSensors: 0,
        smellscapeSensors: 0,
        pollenSensors: 0,
        malaiseSensors: 0,
        weatherSensors: 0,
    });
    const [showMap, setShowMap] = useState<boolean>(true)
    const [filteredBaseStations, setFilteredBaseStations] = useState<BaseStationInterface[]>([])

    const recenterMap = () => {
        if (mapRef.current !== undefined ) {
            // @ts-ignore
            mapRef.current.leafletElement.flyTo([51.133481, 10.018343], 6, {
                duration: 1
            });
        }
    }

    useEffect(()=>{
        setFilteredBaseStations([...app.baseStations])
    },[app.baseStations])

    useEffect(() => {
        let bioSensors:number = 0;
        let cameraSensors:number = 0;
        let smellscapeSensors:number = 0;
        let pollenSensors:number = 0;
        let malaiseSensors:number = 0;
        let weatherSensors:number = 0;
        filteredBaseStations.forEach(bs => {
            bioSensors += bs.sensors.filter(s=>s.type === 'bioacoustic' || s.type === 'bioacustic').length
            cameraSensors += bs.sensors.filter(s=>s.type === 'camera').length
            smellscapeSensors += bs.sensors.filter(s=>s.type === 'smellscape').length
            pollenSensors += bs.sensors.filter(s=>s.type === 'pollen trap').length
            malaiseSensors += bs.sensors.filter(s=>s.type === 'malaise trap').length
            weatherSensors += bs.sensors.filter(s=> s.type.toLowerCase() === "weather station or meteorological observation").length
        })
        setLegend({
            activeBaseStations: filteredBaseStations.filter(bs=>bs.status==='public').length,
            inactiveBaseStations: filteredBaseStations.filter(bs=>bs.status!=='public').length,
            bioSensors,
            cameraSensors,
            smellscapeSensors,
            pollenSensors,
            malaiseSensors,
            weatherSensors,
        })
    }, [filteredBaseStations])

    const changeView = () => {
        setShowMap(!showMap)
    }

    const [resetFilter, setResetFilter] = useState<boolean>(false);
    const handleResetFilters = () => {
        setResetFilter(true)
        setFilteredBaseStations([...app.baseStations])
        setFiltered(false)
    }

    const [filters, setFilters] = useState<HomeFilter[]>([])
    const handleFilterChange = (filters:HomeFilter[]) => {
        setFilters(filters)
    }

    const [isFiltered, setFiltered] = useState<boolean>(false)
    useEffect(()=>{
        setFiltered(filters.length > 1 || (filters.length === 1 && filters[0].value !== ''))
    },[filters])

    return (
        <div className={props.classes.page}>
            <NavBar />
            <Container component="main" maxWidth={"xl"} disableGutters className={classes.main}>
                <CssBaseline />

                {/* APP BAR */}
                {/* for future filters */}
                {/*<AppBar position="sticky" className={classes.appBar} elevation={1}>*/}
                {/*    <Toolbar>*/}
                {/*        <Typography variant="h6">*/}
                {/*            Base stations overview*/}
                {/*        </Typography>*/}
                {/*        <Button color="inherit">Login</Button>*/}
                {/*    </Toolbar>*/}
                {/*</AppBar>*/}

                <Container maxWidth={"xl"}>
                    <Grid container spacing={3} className={classes.header}>
                        <Grid item xs={6}>
                            <Typography variant="h3" display={"inline"} className={`${props.classes.title}`}>
                                Devices overview
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end" alignItems="center" direction="row" spacing={3}>
                            {!showMap ? null : <Grid item>
                                <Button
                                    variant="contained"
                                    className={props.classes.button}
                                    startIcon={<CenterFocusStrongIcon />}
                                    onClick={recenterMap}
                                >
                                    Recenter
                                </Button>
                            </Grid>}

                            <Grid item>
                                <Button
                                    variant="contained"
                                    className={props.classes.button}
                                    startIcon={showMap ? <ViewComfy /> : <MapIcon />}
                                    onClick={changeView}
                                >
                                    {showMap ? 'Table View' : 'Map View'}

                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.legend}>
                        <div className={`${classes.legendRow}`}>
                            <Tooltip 
                                    title={<p className={classes.tooltip}><strong>Active base stations</strong></p>}
                                    arrow={true}
                                    placement='top'
                                >
                                    <span className={classes.sensorWrapper}>
                                        <img src='/static/img/marker-icon-2x-blue.png' alt=""  />
                                        <div>
                                            {legend.activeBaseStations}
                                        </div>
                                    </span>
                            </Tooltip>
                        </div>

                        <div className={classes.legendRow}>
                            <Tooltip 
                                    title={<p className={classes.tooltip}><strong>Inactive base stations</strong></p>}
                                    arrow={true}
                                    placement='top'
                                >
                                    <span className={classes.sensorWrapper}>
                                        <img src='/static/img/marker-icon-2x-red.png' alt="" />
                                        <div>
                                            {legend.inactiveBaseStations}
                                        </div>
                                    </span>
                            </Tooltip>
                        </div>

                        <div className={classes.sensorWrapper}>
                            <Tooltip 
                                    title={<p className={classes.tooltip}><strong>Bioacoustic sensors</strong></p>}
                                    arrow={true}
                                    placement='top'
                                >
                                    <span className={classes.sensorWrapper}>
                                        <img src='/static/img/acoustic.png' alt="" width="24" className={classes.sensorImg}/>
                                        <div>
                                            {legend.bioSensors}
                                        </div>
                                    </span>
                            </Tooltip>  
                        </div>

                        <div className={classes.sensorWrapper}>
                            <Tooltip 
                                    title={<p className={classes.tooltip}><strong>Camera sensors</strong></p>}
                                    arrow={true}
                                    placement='top'
                                >
                                    <span className={classes.sensorWrapper}>
                                        <img src='/static/img/camera.png' alt="" width="24" className={classes.sensorImg}/>
                                        <div>
                                            {legend.cameraSensors}
                                        </div>
                                    </span>
                            </Tooltip>  
                        </div>

                        <div className={classes.sensorWrapper}>
                            <Tooltip 
                                    title={<p className={classes.tooltip}><strong>Smellscape sensors</strong></p>}
                                    arrow={true}
                                    placement='top'
                                >
                                    <span className={classes.sensorWrapper}>
                                        <img src='/static/img/wind.png' alt="" width="24" className={classes.sensorImgWind}/>
                                        <div>
                                            {legend.smellscapeSensors}
                                        </div>
                                    </span>
                            </Tooltip>  
                        </div>

                        <div className={classes.sensorWrapper}>
                            <Tooltip 
                                    title={<p className={classes.tooltip}><strong>Pollen traps</strong></p>}
                                    arrow={true}
                                    placement='top'
                                >
                                    <span className={classes.sensorWrapper}>
                                        <img src='/static/img/flower.png' alt="" width="24" className={classes.sensorImg}/>
                                        <div>
                                            {legend.pollenSensors}
                                        </div>
                                    </span>
                            </Tooltip>  
                        </div>
                        
                        <div className={classes.sensorWrapper}>
                            <Tooltip 
                                    title={<p className={classes.tooltip}><strong>Malaise traps</strong></p>}
                                    arrow={true}
                                    placement='top'
                                >
                                    <span className={classes.sensorWrapper}>
                                        <img src='/static/img/bug.png' alt="" width="24" className={classes.sensorImg}/>
                                        <div>
                                            {legend.malaiseSensors}
                                        </div>
                                    </span>
                            </Tooltip>  
                        </div>
                        
                        <div className={classes.sensorWrapper}>
                            <Tooltip 
                                    title={<p className={classes.tooltip}><strong>Weather sensors</strong></p>}
                                    arrow={true}
                                    placement='top'
                                >
                                    <span className={classes.sensorWrapper}>
                                        <img src='/static/img/weather.png' alt="" width="24" className={classes.sensorImg}/>
                                        <div>
                                            {legend.weatherSensors}
                                        </div>
                                    </span>
                            </Tooltip>  
                        </div>

                        {isFiltered ? <div className={classes.resetFilter}>
                                <Button
                                    variant="contained"
                                    onClick={handleResetFilters}
                                    startIcon={<ClearOutlinedIcon/>}
                                    className={props.classes.button}>
                                    Reset filters
                                </Button>
                            </div>
                            :
                            null
                        }
                    </Grid>

                    {showMap ? <Paper elevation={3} className={classes.map}>
                            <Leaflet mapRef={mapRef} isFiltered={isFiltered} stations={filteredBaseStations}/>
                    </Paper>
                    : <div>

                        <FilterContext.Provider value={{
                            reset: resetFilter,
                            setReset: setResetFilter,
                        }}>
                            <HomeFilterTable
                                setFilteredBaseStations={setFilteredBaseStations}
                                filters={filters}
                                onFilter={handleFilterChange}/>

                        </FilterContext.Provider>

                    </div>}

                </Container>
            </Container>

        </div>
    );
}

const styles = theme => ({
    ...MyTypography(theme),
    ...Inputs(theme),
    ...Elements(),
});

// @ts-ignore
export default withStyles(styles)(Home)
