import {DOWNLOAD_URL} from "../../constants/URLS";
import {
    AppContextInterface,
    DownloadResponse,
} from "../../utils/types";
import {RefreshToken} from "../../utils/helpers";

const showErr = (app:AppContextInterface, msg?:string) => {
    app.setSnackBar({
        open:true,
        msg: msg ? msg: "Server error, please try again later.",
        variant: "error",
        timeout: 3000,
    });
}
const showTooLong = (app:AppContextInterface) => {
    app.setSnackBar({
        open:true,
        msg:"We will send you an email to: " + app.user.email + ", when zip file is ready.",
        variant: "info",
        timeout: null,
    });
}

export const download = async (app:AppContextInterface, params:string[][]):Promise<void> => {
    const url = DOWNLOAD_URL + '?' + new URLSearchParams(params).toString();

    // 20 sec - same as on the backend
    const timeout = 20000;
    const controller = new AbortController();
    const timer = setTimeout(() => controller.abort(), timeout);
    try {
        app.setSnackBar({
            open:true,
            msg:"Please wait for 20 sec for your request to be processed.",
            variant: "info",
            timeout: 5000,
        });
        let status:number = 0;
        let body = {
            method: 'GET',
            signal: controller.signal,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': app.user.token.value,
            }
        };
        let errorMessage = '';
        let res:DownloadResponse|null = await fetch(
            url,
            body
            ).then(async resp => {
                // force refresh token if got 401
                if (resp.status === 401) {
                    const newToken:string = await RefreshToken(app, true);
                    if (newToken === '') return null;
                    body.headers['x-access-token'] = newToken;
                    return await fetch(
                        url,
                        body
                    ).then(resp => {
                        status = resp.status;
                        return resp.json()
                    })
                }
                status = resp.status;
                if (status === 400) {
                    // extract error message from the server
                    const emsg = await resp.clone().json();
                    errorMessage = emsg.error;
                    errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
                }

                return resp.json()
        });

        clearTimeout(timer);

        if (res === null) return;

        if ( res.url ) {
            const link = document.createElement('a');
            link.id = 'ammod_download_' + new Date().toString();
            link.href = res.url;
            link.download = "ammod_data.zip";
            link.click();
            link.remove();
            return
        }

        if (status === 202) {
            showTooLong(app);
        } else if (status === 400) {
            // there are 2 400 requests, nothing found or download is too big
            // in UI we can't download something if there are no files found
            showErr(app, res.error ? res.error : "Maximum download request size is 1GB");
        } else {
            showErr(app);
        }

    } catch (e:any) {
        clearTimeout(timer);
        if( e.name === "AbortError") {
            showTooLong(app);
            return
        }
        console.log(e)
        showErr(app);
    }

}
