import React, {useContext, useEffect, useMemo, useState} from "react";
import {HomeFilter, SelectorItem} from "../../utils/types";
import CheckIcon from "@material-ui/icons/Check";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import useDidUpdateEffect from "../../CustomHooks/useDidUpdateEffect";
import {FilterContext} from "../../App";
import MaUTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import IconButton from "@material-ui/core/IconButton";
import FilterAlt from "@material-ui/icons/FilterList";
import {Button, Popover, TextField} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../styles/colors";
import { useTable, useSortBy, useResizeColumns, useBlockLayout } from 'react-table'
import {TableWrapper} from "./TableWrapper";

const useStyles = makeStyles(theme => ({
    filterRow: {
        display: 'flex',
        margin: '12px 0',
    },
    filterRowIconButton: {
        display: 'inline-block',
        alignSelf: 'flex-end',
        padding: '0 6px',
        marginBottom: '6px',
    },
    addFilterWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
            maxHeight: '25px',
            margin: '8px',
            fontWeight: 'bold',
            backgroundColor: colors.main,
            '&:hover': {
                backgroundColor: colors.selected,
                color: '#fff'
            }
        },
        '& button span': {
            textTransform: 'none',
        }
    },
}))

function FilterTable({ columns, data, homeFilters, onFilter }) {
    const classes = useStyles();
    const [canUpdateFilters, setCanUpdateFilters] = useState<boolean>(true)
    const [filters, setFilters] = useState<HomeFilter[]>(homeFilters)
    const columnsList = [
        {
            value: 'deviceID',
            label: 'DeviceID'
        },
        {
            value: 'type',
            label: 'Type'
        },
        {
            value: 'model',
            label: 'Model'
        },
        {
            value: 'baseStationLongName',
            label: 'Base Station'
        },
        {
            value: 'status',
            label: 'Status'
        }
    ]
    const operators:SelectorItem[] = [
        {
            value: 'contains',
            label: 'contains'
        },
        {
            value: 'equals',
            label: 'equals'
        },
        {
            value: 'starts with',
            label: 'starts with'
        },
        {
            value: 'ends with',
            label: 'ends with'
        },
    ]

    const handleFilterChange = <K extends keyof HomeFilter>(
        ev: React.ChangeEvent<HTMLInputElement  | HTMLTextAreaElement>, fid: number, key: K) => {
        const newFilters = [...filters];
        newFilters[fid][key] = ev.target.value;
        setFilters(newFilters)
        // delay table data redraw
        if (canUpdateFilters) {
            setCanUpdateFilters(false)
            setTimeout(() => {
                setCanUpdateFilters(true)
            }, 500)
        }
    };

    const handleFilterDelete = (fid:number) => {
        const newFilters = [...filters];
        const col = newFilters[fid].column;
        newFilters.splice(fid,1)
        if (newFilters.length === 0) {
            newFilters.push({
                column: col,
                operator: 'contains',
                value: ''
            })
        }
        setFilters(newFilters)
        onFilter(newFilters)
    }

    const handleAddFilter = () => {
        setFilters([...filters, {
            column: 'deviceID',
            operator: 'contains',
            value:''
        }])
    }

    const defaultColumn = useMemo(
        () => ({
            minWidth: 100,
            width: 150,
            maxWidth: 400,
        }),
        []
    )

    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
        defaultColumn,
    }, useSortBy, useBlockLayout, useResizeColumns )

    const renderCell = (cell, i) => {
        const dataRow = data.find(d=>d.deviceID===cell.row.cells[0].value)
        switch (i) {
            case 0:
                return <a href={dataRow.citation} target="_blank" rel="noreferrer">{cell.render('Cell')}</a>
            case 3:
                // base station
                return <a href={dataRow.baseStationCitation} target="_blank" rel="noreferrer">{dataRow.baseStationLongName}</a>
            case 4:
                return <div className={`status-label ${cell.value === 'active' ? 'status-active' : 'status-inactive'}`}>
                    {cell.value === 'active' ? <CheckIcon /> : <WarningRoundedIcon />}
                    <span>{cell.render('Cell')}</span>
                </div>
            default:
                return cell.render('Cell')
        }
    }

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (ev:React.SyntheticEvent<EventTarget>) => {
        // If event target not an HTMLButtonElement, exit
        if (!(ev.target instanceof HTMLButtonElement)) {
            return;
        }
        if (!ev.target.dataset.tid || !ev.target.dataset.column) {
            return;
        }
        const cell: HTMLElement | null = document.getElementById(ev.target.dataset.tid)
        const columnName:string = ev.target.dataset.column
        setAnchorEl(cell);
        // if empty or no value, change column
        if (filters.length === 0 || (filters.length === 1 && filters[0].value === '')) {
            setFilters([{
                column: columnName,
                operator: 'contains',
                value: ''
            }])
        } else {
            // add column filter if not present
            if (!filters.find(filter=>filter.column === columnName))
            setFilters([...filters, {
                column: columnName,
                operator: 'contains',
                value: ''
            }])
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showFilter = Boolean(anchorEl);

    useDidUpdateEffect(() => {
        if (canUpdateFilters) {
            onFilter(filters)
        }
    }, [canUpdateFilters])

    const filterContext = useContext(FilterContext);
    useEffect(()=>{
        if (filterContext.reset) {
            filterContext.setReset(false)
            const col = filters.length > 0 ? filters[0].column : 'deviceID'
            const newFilters:HomeFilter[] = [{
                column: col,
                operator: 'contains',
                value: ''
            }]
            setFilters(newFilters)
            onFilter(newFilters)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterContext.reset])

    // Render the UI for your table
    return (
        <TableWrapper>
            <MaUTable {...getTableProps()}>
                <TableHead>
                    {headerGroups.map((headerGroup, tid) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column,i) => (
                                <TableCell {...column.getHeaderProps()} id={`trtcid-${tid}-${i}`}>
                                    <div {...column.getSortByToggleProps()}>
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span className="search-arrow-wrapper">
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <span className="search-arrow"><ArrowDownward /></span>
                                                : <span className="search-arrow"><ArrowUpward /></span>
                                            : ''}
                                    </span>
                                    </div>

                                    {/* Options hover button */}
                                    <IconButton
                                        size="small"
                                        onClick={handleClick}
                                        data-column={column.id}
                                        data-tid={`trtcid-${tid}-${i}`}
                                        className="opt-icon-wrapper">
                                        <FilterAlt fontSize="small" />
                                    </IconButton>
                                    <Popover
                                        id={`trtcid-${tid}`}
                                        open={showFilter}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        elevation={3}
                                        classes={{ paper: "MuiPopover-paper" }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >

                                        {/* filter row */}
                                        { filters.map((filter, fid) => <div
                                                className={classes.filterRow} key={`tfr-${fid}`}>
                                                <div className={classes.filterRowIconButton}>
                                                    <IconButton
                                                        aria-label="delete"
                                                        size="small"
                                                        onClick={()=>{handleFilterDelete(fid)}}>
                                                        <Close fontSize="small"/>
                                                    </IconButton>
                                                </div>

                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    label="Columns"
                                                    value={filter.column}
                                                    onChange={(e)=>handleFilterChange(e, fid, 'column')}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                >
                                                    {columnsList.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    label="Operator"
                                                    value={filter.operator}
                                                    onChange={(e)=>handleFilterChange(e, fid, 'operator')}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                >
                                                    {operators.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Value"
                                                    placeholder="Filter value"
                                                    autoComplete="off"
                                                    value={filter.value}
                                                    onChange={(e)=>{
                                                        handleFilterChange(e, fid, 'value')
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>
                                        )}

                                        {/* add filter button */}
                                        <div className={classes.addFilterWrapper}>
                                            <Button
                                                variant="contained"
                                                // className={props.classes.button}
                                                startIcon={<AddIcon />}
                                                onClick={handleAddFilter}

                                            >
                                                Add Filter
                                            </Button>
                                        </div>
                                    </Popover>

                                    {/* Use column.getResizerProps to hook up the events correctly */}
                                    <div
                                        {...column.getResizerProps()}
                                        className={`${i === headerGroup.headers.length -1 ? '' : 'resizer'} ${
                                            column.isResizing ? 'isResizing' : ''
                                        }`}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>

                <TableBody>
                    {rows.map((row)  => {
                        prepareRow(row)
                        return (
                            <TableRow {...row.getRowProps()}>
                                {row.cells.map((cell,i) => {
                                    return (
                                        <TableCell {...cell.getCellProps()}>
                                            { renderCell(cell, i) }
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </MaUTable>
        </TableWrapper>
    )
}

export default FilterTable
