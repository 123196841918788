const server = process.env.NODE_ENV === 'development' ? "http://localhost/" : "/";
// SERVER_TOKEN should be manually inserted here from django admin when developing with npm run start
export const SERVER_TOKEN = process.env.NODE_ENV === 'development' ? '01e950510c81a1079170c50b92bb' : '';

export const BASE_STATIONS_INFO = server + "api/v1/basestations"
export const META_DATA_VALIDATION = server + "api/v1/json/metadata"
export const TELEMETRY_VALIDATION = server + "api/v1/json/telemetry"
export const USER_TOKEN_REFRESH = server + "api/v1/user/token/refresh"
export const USER_API_TOKEN = server + "api/v1/user/token/api"
export const USER_LOGOUT_OIDC = server + "oidc/logout/"
export const META_DATA_UPLOAD = server + "api/v1/metadata"
export const TELEMETRY_UPLOAD = server + "api/v1/telemetry"
export const SEARCH_URL = server + "api/v1/search/metadata"
export const TELEMETRY_URL = server + "api/v1/search/telemetry"
export const DOWNLOAD_URL = server + "api/v1/download/metadata"
