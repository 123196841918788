import React, {useContext} from 'react'
import Leaflet from 'leaflet';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../styles/colors";
import SearchIcon from "@material-ui/icons/Search";
import {Button} from "@material-ui/core";
import {AppContext} from "../App";
import {BaseStationInterface} from "../utils/types";

Leaflet.Icon.Default.imagePath = '../node_modules/leaflet'
delete Leaflet.Icon.Default.prototype._getIconUrl;
Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const useStyles = makeStyles(theme => ({
    popup: {
    },
    row : {
        display: 'block',
        marginBottom: '8px'
    },
    title: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
    bold: {
        fontWeight: 'bold',
    },
    link: {
        textDecoration: 'none',
        fontWeight: 'bold',
        '&:hover': {
            color: colors.selected,
        }
    },
    searchButton: {
        fontSize: '12px',
        width: '135px',
        height: '28px',
        display: 'flex',
        marginTop: '18px',
        fontWeight: 'bold',
        maxWidth: '200px',
        backgroundColor: colors.main,
        '&:hover': {
            backgroundColor: colors.selected,
            color: '#fff'
        }
    },
    sensorWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    sensorInactive: {
        marginLeft: '5px',
        fontWeight: 'bold',
        color: 'red'
    }
}))

const germany_center:number[] = [51.133481, 10.018343]

export default function MapChart({mapRef, isFiltered, stations}:{
    mapRef: React.MutableRefObject<any>,
    isFiltered: boolean,
    stations: BaseStationInterface[],
}) {
    const app = useContext(AppContext);
    const classes = useStyles()

    const newSearch = (baseStation:BaseStationInterface) => {
        app.setSearchBaseStation(baseStation)
    }
    const inactiveIcon = new Leaflet.Icon({
        iconUrl: '/static/img/marker-icon-2x-red.png',
        shadowUrl: '/static/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });
    const activeIcon = new Leaflet.Icon({
        iconUrl: '/static/img/marker-icon-2x-blue.png',
        shadowUrl: '/static/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    const markers = stations.map((station, i) => (
        <Marker
            position={[station.position.lat, station.position.long]}
            key={`station-${i}`}
            icon={station.status === 'public' ? activeIcon : inactiveIcon} >
            <Popup className={classes.popup}>
                <span className={`${classes.row} ${classes.title}`}><a href={station.citation}
                                                   rel="noopener noreferrer"
                                                   target={"_blank"}
                                                   className={classes.link}>{station.longName}</a></span>
                {/*<span>Last update: {station.lastModified}</span>*/}
                {station.sensors.length === 0 ? <span className={`${classes.row} ${classes.bold}`}>
                        {isFiltered ? 'No sensors found' : 'No sensors installed'}</span>
                    :  <span className={`${classes.row} ${classes.bold}`}>
                        {isFiltered ? 'Filtered sensors:' : 'Installed sensors:'}</span>}
                {station.sensors.map(s => <span className={`${classes.row}`}>
                    <div className={classes.sensorWrapper}>
                        <a href={s.citation}
                        rel="noopener noreferrer"
                        target={"_blank"}
                        className={classes.link}>
                            {s.type.charAt(0).toUpperCase() + s.type.slice(1)}
                        </a>
                        {s.status === 'public' ? null : <div className={classes.sensorInactive}>(inactive)</div>}
                    </div>
                    </span>)}

                { ( ( app.user.token.value !== '' || process.env.NODE_ENV === 'development' )
                    && station.sensors.length > 0) ?
                        <span>
                            <Button
                                variant="contained"
                                id="search-button"
                                className={`${classes.searchButton}`}
                                startIcon={<SearchIcon/>}
                                onClick={() => {
                                    newSearch(station)
                                }}
                            >
                                Show Data
                            </Button>
                        </span>
                        : null
                }
            </Popup>
        </Marker>
    ))

    return (
        <Map ref={mapRef} center={germany_center} zoom={6} style={{height : '70vh'}}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {markers}
        </Map>
    );
}
