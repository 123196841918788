import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AppContext } from "../App";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControl, FormLabel } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from '@material-ui/core/Grid/Grid';
import {
    CustomSearch,
    logicalOperatorValues,
    SearchCheckedItems, SearchFilterInfo
} from "../utils/types";
import Button from '@material-ui/core/Button/Button';
import SearchIcon from "@material-ui/icons/Search";
import { Elements, Inputs, Typography as MyTypography } from "../styles";
import ClearIcon from '@material-ui/icons/Clear';
import TimeFrameSelector from "./TimeFrameSelector";
import LocationSelector from "./LocationSelector";
import SensorsSelector from "./SensorsSelector";
import DialogWrapper from "./DialogWrapper";
import { setDefaultCheckedState, DateToString } from "../utils/helpers";
import AdvancedSearch from "./AdvancedSearch";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
        marginBottom: '15px',
    },
    formGroup: {
        flexDirection: 'row',
    },
    groupedCheckBox: {
        minWidth: '136px',
    },
    formLabel: {
        marginBottom: '10px'
    },
    searchButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
            margin: '0 10px',
            minWidth: '160px'
        },
    }
}));

function SearchFilter(props) {
    const app = useContext(AppContext);
    const classes = useStyles();
    const [customSearch, setCustomSearch] = useState<CustomSearch[]>([]);
    const [startDate, setStartDate] = useState(new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        new Date().getDate(),

    ));
    const [stopDate, setStopDate] = useState(new Date());
    const [customQuery, setCustomQuery] = useState<string[][]>([]);
    const [checkedBaseStations, setCheckedBaseStations] = useState<SearchCheckedItems>({});
    const [checkedSensors, setCheckedSensors] = useState<SearchCheckedItems>({});
    const [sensorIDs, setSensorIDs] = useState<string[]>([]);
    const [showProcessedData, setShowProcessedData] = useState<boolean>(true);
    const [showRawData, setShowRawData] = useState<boolean>(true);

    const handleBaseStationChange = (values) => {
        setCheckedBaseStations(values);
    };

    const handleSensorChange = (values) => {
        setCheckedSensors(values);
    };

    const handleShowRawDataChange = (event) => {
        if (!showProcessedData && !event.target.checked) {
            setShowProcessedData(true)
        }
        setShowRawData(event.target.checked);
    }

    const handleShowProcessedDataChange = (event) => {
        if (!showRawData && !event.target.checked) {
            setShowRawData(true)
        }
        setShowProcessedData(event.target.checked);
    }



    const makeParams = (startSearch?: boolean): SearchFilterInfo => {
        let sri: SearchFilterInfo = {
            params: [],
            new_search: startSearch === true,
            shared_query: false,
        }
        if (startSearch !== true) {
            // return empty struct
            return sri
        }
        const params: string[][] = [];
        const freeFloatingDeviceIDs: string[] = [];
        // get location of selected base stations
        const selectedBaseStationNames: string[] = [];
        const allBS = [...app.baseStations];
        for (const key in checkedBaseStations) {
            if (checkedBaseStations.hasOwnProperty(key) && checkedBaseStations[key]) {
                const bs = allBS.find(s => s.longName === key);
                if (bs) {
                    if (bs.type === 'station') {
                        // add location to params and remove found baseStation from search array
                        selectedBaseStationNames.push(bs.position.long + ',' + bs.position.lat);
                        allBS.splice(allBS.indexOf(bs), 1);
                    } else {
                        // for free-floating sensor add deviceID instead of location
                        freeFloatingDeviceIDs.push(bs.id)
                    }
                }
            }
        }
        if (selectedBaseStationNames.length > 0 && selectedBaseStationNames.length !== app.baseStations.length) {
            selectedBaseStationNames.forEach((loc: string) => {
                params.push(['location', loc]);
            })
        }

        if (Object.keys(checkedSensors).find(key => !checkedSensors[key]) || freeFloatingDeviceIDs.length > 0) {
            const allSensorIDs = [...sensorIDs, ...freeFloatingDeviceIDs];
            params.push(['sensors', allSensorIDs.join(',')]);
        }

        params.push(['startTime', DateToString(startDate)]);
        params.push(['stopTime', DateToString(stopDate)]);
        if (customQuery.length > 0) {
            params.push(...customQuery);
        }

        if (!showProcessedData || !showRawData) {
            let dataType: string[] = [];
            if (showRawData) {
                dataType.push("raw");
            }
            if (showProcessedData) {
                dataType.push("processed");
            }
            params.push(["dataType", dataType.join(",")])
        }

        sri.params = params;

        return sri;
    }

    const resetFilter = () => {
        setCustomSearch([]);
        setStartDate(new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 1,
            new Date().getDate(),
        ));
        setStopDate(new Date());
        setShowRawData(true)
        setShowProcessedData(true)
        setCheckedBaseStations(setDefaultCheckedState(app.baseStations.map(s => s.longName), false));
        setCheckedSensors(setDefaultCheckedState(app.sensors, true))
    }

    const closeNoChange = () => {
        props.onClose()
    }

    const handleTimeFrame = (dStart: Date, dStop: Date) => {
        if (dStart.valueOf() !== startDate.valueOf()) {
            // start date change
            if (dStart.valueOf() > dStop.valueOf()) {
                const newStopDate = new Date(dStart)
                newStopDate.setHours(23, 59, 59, 0)
                setStartDate(dStart)
                setStopDate(newStopDate)
            } else {
                setStartDate(dStart)
            }
            return
        }

        if (dStop.valueOf() !== stopDate.valueOf()) {
            // stop date changed
            if (dStart.valueOf() > dStop.valueOf()) {
                const newStartDate = new Date(dStop)
                newStartDate.setHours(0, 0, 0, 0)
                setStartDate(newStartDate)
                setStopDate(dStop)
            } else {
                setStopDate(dStop)
            }
        }
    }

    useEffect(() => {
        if (Object.keys(checkedSensors).find(key => !checkedSensors[key])) {
            // if one of the sensors is not selected
            let tempIDs: string[] = [];

            Object.keys(checkedSensors).forEach(key => {
                if (checkedSensors[key]) {
                    app.baseStations.forEach(bs => {
                        bs.sensors.forEach(s => {
                            if (s.type.toLocaleLowerCase() === key.toLowerCase()) {
                                tempIDs.push(s.id)
                            }
                        })
                    })
                }
            })
            tempIDs = tempIDs.filter((e, i) => tempIDs.indexOf(e) === i)
            setSensorIDs(tempIDs)
        } else {
            setSensorIDs([])
        }
    }, [app.baseStations, checkedSensors])

    useEffect(() => {
        setCheckedBaseStations(setDefaultCheckedState(app.baseStations.map(s => s.longName), false));
        setCheckedSensors(setDefaultCheckedState(app.sensors, true))
    }, [app.baseStations, app.sensors])


    useEffect(() => {
        const cq: string[][] = [];
        customSearch.forEach(cs => {
            const key: string = cs.key.trim();
            const value: string = cs.value.trim();
            let q: string = key + ',' + value;
            if (key !== '' && value !== '') {
                // add operator if it's not default value
                if (cs.operator > 0) {
                    q += ',' + logicalOperatorValues[cs.operator].query;
                }
                cq.push(['cs', String(q)]);
            }
        })
        setCustomQuery(cq)
    }, [customSearch])

    return (
        <DialogWrapper open={props.open} onClose={closeNoChange} title={"Filters"}>
            <Grid container spacing={3}>
                {/* TIME FRAME*/}
                <Grid container item xs={6}>
                    <TimeFrameSelector
                        callback={handleTimeFrame}
                        startDate={startDate}
                        stopDate={stopDate}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Data Type</FormLabel>
                        <FormGroup className={classes.formGroup}>
                            <FormControlLabel
                                className={classes.groupedCheckBox}
                                key={`sens-rd`}
                                control={
                                    <Checkbox
                                        checked={showRawData}
                                        onChange={handleShowRawDataChange}
                                        name={"raw-data"}
                                        color="primary" />
                                }
                                label={"Raw Data"}
                            />
                            <FormControlLabel
                                className={classes.groupedCheckBox}
                                key={`sens-pd`}
                                control={
                                    <Checkbox
                                        checked={showProcessedData}
                                        onChange={handleShowProcessedDataChange}
                                        name={"raw-data"}
                                        color="primary" />
                                }
                                label={"Processed Data"}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <LocationSelector
                        values={checkedBaseStations}
                        allowAny={true}
                        callback={handleBaseStationChange} />
                </Grid>

                <Grid container item xs={12} md={6}>
                    <Grid item xs={12} md={12}>
                        <SensorsSelector
                            sensors={app.sensors}
                            values={checkedSensors}
                            callback={handleSensorChange} />
                    </Grid>
                </Grid>

                <AdvancedSearch customSearch={customSearch} callback={setCustomSearch} />

                <Grid item xs={12} className={classes.searchButtonWrapper}>
                    <Button
                        variant="contained"
                        id="search-button"
                        className={`${props.classes.buttonCancel}`}
                        startIcon={<ClearIcon />}
                        onClick={resetFilter}
                    >
                        Reset Filter
                    </Button>
                    <Button
                        variant="contained"
                        id="search-button"
                        className={`${props.classes.button}`}
                        startIcon={<SearchIcon />}
                        onClick={() => {
                            props.onClose(makeParams(true))
                        }}
                    >
                        Search
                    </Button>
                </Grid>

            </Grid>
        </DialogWrapper>
    );
}

SearchFilter.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const globalStyles = theme => ({
    ...MyTypography(theme),
    ...Inputs(theme),
    ...Elements(),
});

// @ts-ignore
export default withStyles(globalStyles)(SearchFilter)
