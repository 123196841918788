import {FormControl, FormLabel} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
        marginBottom: '15px',
    },
    formGroup: {
        flexDirection: 'row',
    },
    anyLocation: {
        width: '100%'
    }
}))

export default function LocationSelector(props) {
    const classes = useStyles();
    const { values, allowAny} = props;
    const [anyLocation, setAnyLocation] = useState<boolean>(true);
    const handleLocationChange = (event) => {
        let newValues = {...values, [event.target.name]: event.target.checked}
        let hasChecked = false;
        for (const k in newValues) {
            if (newValues.hasOwnProperty(k)) {
                if (newValues[k] === true ) hasChecked = true;
            }
        }
        setAnyLocation(!hasChecked)
        props.callback({...values, [event.target.name]: event.target.checked});
    };

    const handleSelectAny = (event) => {
        if (event.target.checked) {
            let newValues = {...values};
            for (const k in newValues) {
                if (newValues.hasOwnProperty(k)) {
                    newValues[k] = false;
                }
            }
            props.callback(newValues);
        }
        if (event.target.checked) {
            setAnyLocation(event.target.checked)
        }

    }

    const loadCheckboxes = ():JSX.Element[] => {
        if (Object.keys(values).length === 0) return [];
        let locations:JSX.Element[] = [];
        let i = 0;

        for (const k in values) {
            if (values.hasOwnProperty(k)) {
                locations.push(<FormControlLabel
                    key={`bs-res-${i}`}
                    control={<Checkbox
                        checked={values[k]}
                        onChange={handleLocationChange}
                        name={k}
                        color="primary"/>}
                    label={k}
                />)
                i++;
            }

        }
        return locations;
    }

    useEffect(() => {
        let hasChecked = false;
        for (const k in values) {
            if (values.hasOwnProperty(k) && !hasChecked) {
                if (values[k] === true) {
                    setAnyLocation(false)
                    hasChecked = true;
                }
            }
        }
        if (!hasChecked) {
            setAnyLocation(true)
        }
    }, [values])

    return <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Select Location</FormLabel>
        <FormGroup className={classes.formGroup}>
            { !allowAny ? null : <FormControlLabel
                key={`bs-res-a`}
                className={classes.anyLocation}
                control={<Checkbox
                    checked={anyLocation}
                    onChange={handleSelectAny}
                    name={"Any Location"}
                    color="primary"/>}
                label={"Any Location"}
            /> }
            {loadCheckboxes()}
        </FormGroup>
    </FormControl>
}

LocationSelector.propTypes = {
    callback: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    showAllowAny: PropTypes.bool,
    allowAny: PropTypes.bool,
};
