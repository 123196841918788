import styled from 'styled-components'
export const TableWrapper = styled.div`
 
  .opt-icon-wrapper {
    position: absolute;
    right: 10px;
    top: 8px;
    transition: all 0.3s ease-in-out;
    svg, span {
      pointer-events: none;
    }
  }

  a, a:focus, a:visited {
    color: #0078A8;
    text-decoration: none;
    font-weight: bold;
  }
  
  // FireFox specific margin, otherwise on full width overflow-x is shown
  @-moz-document url-prefix() {
    .MuiTable-root {
      margin-right: 10px;
    }
  }
  
  .MuiTable-root {
    width: max-content;
    border-spacing: 0;
    table-layout: fixed;

    .MuiTableHead-root {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: #fcfcfc;
      tr th:first-child {
        border-top-left-radius: 4px;
      }
    }

    tbody:after {
      content: "";
      background: black;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 50%;
      width: 1px;
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .MuiTableBody-root {
      tr:hover {
        background-color: #eee;
      }
 
      .status-label {
        display: flex;
        justify-content: flex-start;
        font-weight: bold;
        border: 1px solid;
        border-radius: 4px;
        max-width: 85px;
        pointer-events: none;
        align-items: center;
      }
      .status-label svg {
        height: 24px;
        max-height: 24px;
        width: 24px;
        max-width: 24px;
        margin: 0 4px;
      }
      .status-active {
        color: green;
        border-color: green;
      }
      .status-active svg {
        color: green;
      }
      .status-inactive {
        color: red;
        border-color: red;
        max-width: 95px;
        align-items: center;
      }
      .status-inactive svg{
        color: red;
      }
    }
    
    .MuiTableCell-head {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently
                                       supported by Chrome, Edge, Opera and Firefox */
      font-weight: bold;
    }

    .MuiTableCell-root,
    .td {
      margin: 0;
      padding: 0.5rem;

    ${'' /* In this example we use an absolutely position resizer,
       so this is required. */}
      position: relative;

      .resizer {
        display: inline-block;
        background: #65bc7b;
        min-width: 5px;
        width: 5px;
        height: 60%;
        position: absolute;
        right: -1px;
        top: 20%;
        transform: translateX(50%);
        z-index: 1;
      ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action: none;

        &.isResizing {
          background: #e8501f;
        }
      }
    }
  }
  .search-arrow-wrapper {
    position: relative;
  }
  .search-arrow {
    left: 4px;
    position: absolute;
  }
`
