import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

// https://www.chartjs.org/docs/latest/
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

const useStyles = makeStyles(theme => ({
    chart: {
        // maxHeight: '250px',
        // width: 'auto !important'
    },
}));

// https://www.chartjs.org/docs/latest/charts/bar.html
function MyChart(props) {
    const classes = useStyles();
    const chartRef = useRef() as React.MutableRefObject<HTMLCanvasElement>;
    const [chart, setChart] = useState<any>(null);

    useEffect(() => {

        if (!props.shouldUpdate ) return;

        if (chart !== null) {
            chart.destroy()
            setChart(null)
        }

        let c = new Chart(chartRef.current, {
            type: props.type,
            data: props.data,
            options: props.options,
        });
        setChart(c);
        props.updateCallback()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, props.data, props.options, props.type])

    return (
        <canvas ref={chartRef} className={classes.chart} width={300} height={200}/>
    );
}


MyChart.propTypes = {
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    shouldUpdate: PropTypes.bool.isRequired,
    updateCallback: PropTypes.func.isRequired,
    options: PropTypes.object,
};


export default MyChart;
