import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { FileInfo, MetaJson, UploadChunk, UploadChunkStatus, UploadChunkStatusEnum } from "../utils/types";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: 10,
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        borderRadius: '5px',
        minHeight: '200px',
        width: '100%',
        height: '100%',
        '& input': {
            display: 'none',
            pointerEvents: 'none',
        }
    },
    disabled: {
        pointerEvents: 'none',
    },
    zone: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    zoneText: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#7A7A7A',
        pointerEvents: 'none',
    },
    uploadIcon: {
        fontSize: '3rem',
        '& path': {
            fill: '#7A7A7A'
        },
        pointerEvents: 'none'
    },
    highlight: {
        backgroundImage: 'repeating-linear-gradient(-45deg,transparent,transparent 1rem,#ccc 1rem,#ccc 2rem)',
        backgroundSize: '200% 200%',
        animation: '$stripes 10s linear infinite'
    },
    "@keyframes stripes": {
        "100%": {
            backgroundPosition: '100% 100%',
        }
    },
}));

DropZone.propTypes = {
    inProgress: PropTypes.func.isRequired,
    noMatch: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    isUploading: PropTypes.bool.isRequired,
};

export default function DropZone(props) {
    const classes = useStyles();
    const zoneRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const preventDefaults = e => {
        e.stopPropagation();
        e.preventDefault();
    }

    const highlight = e => {
        zoneRef.current.classList.add(classes.highlight)
    }

    const unHighlight = e => {
        zoneRef.current.classList.remove(classes.highlight)
    }

    const isMetadata = (json_data: object): boolean => {
        const metadataKeys: string[] = ["deviceID", "serialNumber",
            "timestamp", "location", "usableForResearchPurposes", "files", "sourceFiles"];
        for (const i in metadataKeys) {
            // if key not in json_data
            if (!(metadataKeys[i] in json_data)) {
                return false
            }
        }
        return true
    }

    const isTelemetry = (json_data: object): boolean => {
        const keys: string[] = ["deviceID", "serialNumber",
            "timestamp", "location", "status"];
        for (const i in keys) {
            // if key not in json_data
            if (!(keys[i] in json_data)) {
                return false
            }
        }
        return true
    }

    const organizeFiles = async (filesInfo: FileInfo[]) => {
        const jsons: MetaJson[] = [];
        // read all json files, find metadata and files list
        let missingFileNames: string[] = await Promise.all(filesInfo.map(fi => {
            if (fi.file.name.endsWith('.json')) {
                return new Promise<string>(resolve => {
                    let reader = new FileReader();
                    reader.onload = e => {
                        let parsedData = {};
                        let syntaxError: string = '';
                        if (!e || !e.target || !e.target.result) {
                            syntaxError = 'Could not parse JSON file: ' + fi.file.name;
                        }
                        try {
                            if (syntaxError === '') {
                                // @ts-ignore
                                parsedData = JSON.parse(e.target.result)
                            }
                        } catch (e) {
                            // syntax error or something else
                            // in any case report error
                            if (e instanceof SyntaxError) {
                                syntaxError = 'Could not parse JSON file: ' + fi.file.name + '. ' + e.message;
                            } else {
                                syntaxError = 'Could not parse JSON file: ' + fi.file.name;
                            }
                            parsedData = {};
                        }
                        if (isMetadata(parsedData) || isTelemetry(parsedData)) {
                            jsons.push({
                                file: fi.file,
                                dir: fi.path.split(fi.file.name)[0],
                                // @ts-ignore
                                data: parsedData,
                                syntaxError: syntaxError,
                            });
                        }
                        resolve(fi.path)
                    };
                    reader.readAsText(fi.file);
                });
            }
            return fi.path
        }
        ))

        // at the moment if at least 1 data package was found
        // the unparsed files will be ignored
        if (jsons.length === 0 && filesInfo.length > 0) {
            props.noMatch()
            return
        }

        // iterate through json object to create upload chunks
        // match json file with data files
        const allChunks: UploadChunk[] = [];
        jsons.forEach(j => {
            let uploadChunkStatus: UploadChunkStatus = {
                code: UploadChunkStatusEnum.OK,
                message: ''
            }
            // add json file to upload chunk, it is hidden in the UI but required for upload
            let uploadChunk: UploadChunk = {
                name: j.file.name,
                files: [j.file],
                status: uploadChunkStatus,
                metadataFileName: j.file.name,
                progress: 0,
            }

            // remove json file from list
            missingFileNames.splice(missingFileNames.indexOf(j.dir + j.file.name), 1);
            // check if json was parsed correctly
            if (j.syntaxError !== '') {
                uploadChunk.status.code = UploadChunkStatusEnum.SyntaxError;
                uploadChunk.status.message = j.syntaxError
                allChunks.push(uploadChunk);
                return
            }
            // add as telemetry if no files found
            if (!('files' in j.data)) {
                allChunks.push(uploadChunk);
                return;
            }

            j.data.files.forEach(f => {
                let filtered = filesInfo.filter(ff => ff.path === j.dir + f.fileName)
                if (filtered.length === 1) {
                    uploadChunk.files.push(filtered[0].file)
                    missingFileNames.splice(missingFileNames.indexOf(j.dir + filtered[0].file.name), 1)
                }
            })

            // check when multiple json files, that metadata has proper name
            if (j.data.files.filter(f => f.fileName.includes('.json')).length > 0) {
                if (!j.file.name.includes('metadata')) {
                    uploadChunk.status.code = UploadChunkStatusEnum.MultipleJsonFileNameError;
                    uploadChunk.status.message = "When uploading multiple .json files, metadata file MUST have \"metadata\" in the file name."
                }
            }

            // -1 for removing json files from the equation
            if (uploadChunk.files.length - 1 !== j.data.files.length) {
                let missingFiles = j.data.files.filter(jf => uploadChunk.files.filter(ucf => ucf.name !== jf.fileName).length > 0)
                uploadChunk.status.code = UploadChunkStatusEnum.MissingFiles;
                uploadChunk.status.message = "Missing data files specified in meta data: " + missingFiles.map(mf => {
                    return mf.fileName + ", "
                })
                uploadChunk.status.message = uploadChunk.status.message.substr(0, uploadChunk.status.message.length - 2)
            }
            allChunks.push(uploadChunk)
        })
        // send data to upload view
        props.onDrop(allChunks, missingFileNames)
    }

    const handleDrop = async (e: any) => {
        if (props.isUploading) {
            // TODO snack message wait for upload to finish
            props.inProgress()
            return;
        }
        if (!e.target.files) {
            const fileEntities = await getAllFileEntries(e.dataTransfer.items)
            const userFiles: FileInfo[] = await Promise.all(fileEntities.map(async fe => {
                return new Promise<FileInfo>(resolve => {
                    fe.file((f: File) => resolve({
                        file: f,
                        path: fe.fullPath,
                    }))
                })
            }))
            organizeFiles(userFiles)
        } else {
            // @ts-ignore
            organizeFiles(Array.from(e.target.files).map((f: File) => {
                return {
                    file: f,
                    path: '' + f.name,
                }
            }));
        }
    }

    // Drop handler function to get all files
    async function getAllFileEntries(dataTransferItemList: DataTransferItem[]) {
        let fileEntries: any[] = [];
        // Use BFS to traverse entire directory/file structure
        let queue: any[] = [];
        // Unfortunately dataTransferItemList is not iterable i.e. no forEach
        for (let i = 0; i < dataTransferItemList.length; i++) {
            queue.push(dataTransferItemList[i].webkitGetAsEntry());
        }
        while (queue.length > 0) {
            let entry = queue.shift();
            if (entry) {
                if (entry.isFile) {
                    fileEntries.push(entry);
                } else if (entry.isDirectory) {
                    queue.push(...await readAllDirectoryEntries(entry.createReader()));
                }
            }
        }
        return fileEntries;
    }

    // Get all the entries (files or sub-directories) in a directory
    // by calling readEntries until it returns empty array
    async function readAllDirectoryEntries(directoryReader) {
        let entries: any[] = [];
        let readEntries: any = await readEntriesPromise(directoryReader);
        while (readEntries.length > 0) {
            entries.push(...readEntries);
            readEntries = await readEntriesPromise(directoryReader);
        }
        return entries;
    }

    // Wrap readEntries in a promise to make working with readEntries easier
    // readEntries will return only some of the entries in a directory
    // e.g. Chrome returns at most 100 entries at a time
    async function readEntriesPromise(directoryReader) {
        try {
            return await new Promise((resolve, reject) => {
                directoryReader.readEntries(resolve, reject);
            });
        } catch (err) {
            console.log(err);
        }
    }

    const triggerInput = () => {
        inputRef.current.click()
    }

    // use this effect once after render
    useEffect(() => {
        const zone = zoneRef.current;
        // add event listeners on mount
        ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            zone.addEventListener(eventName, preventDefaults, false)
        })

            ;['dragenter', 'dragover'].forEach(eventName => {
                zone.addEventListener(eventName, highlight, false)
            })

            ;['dragleave', 'drop'].forEach(eventName => {
                zone.addEventListener(eventName, unHighlight, false)
            })
        zone.addEventListener('drop', handleDrop, false)

        return () => {
            // remove event listeners on unmount
            ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                zone.removeEventListener(eventName, preventDefaults, false)
            })

                ;['dragenter', 'dragover'].forEach(eventName => {
                    zone.removeEventListener(eventName, highlight, false)
                })

                ;['dragleave', 'drop'].forEach(eventName => {
                    zone.removeEventListener(eventName, unHighlight, false)
                })
            zone.removeEventListener('drop', handleDrop, false)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div
            ref={zoneRef}
            className={`${classes.root} ${props.isUploading ? classes.disabled : ''}`}
            onClick={triggerInput}
        >
            <div className={classes.zone}>
                <div className={classes.zoneText}>Drag and drop files here or click</div>
                <CloudUpload className={classes.uploadIcon} />
                <input ref={inputRef} type={"file"} multiple={true} onChange={handleDrop} />
            </div>
        </div>
    );
}
