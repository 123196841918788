import {FormControl, FormLabel} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid/Grid";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
        marginBottom: '15px',
    },
    formGroup: {
        flexDirection: 'row',
    },
    formLabel: {
        marginBottom: '10px',
        zIndex: 1000
    },
    searchDate: {
        padding: '10px 10px 0 0',
        '& input': {
            padding: '10px',
            maxWidth: '145px',
        }
    },
}))


export default function TimeFrameSelector(props) {
    const classes = useStyles();

    return <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend" className={classes.formLabel}>Select Time Frame</FormLabel>
        <FormGroup className={classes.formGroup}>
            <Grid item className={classes.searchDate}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        autoOk
                        ampm={false}
                        disableFuture
                        value={props.startDate}
                        inputVariant="outlined"
                        format="dd.MM.Y HH:mm"
                        onChange={(date) => {props.callback(date, props.stopDate)}}
                        label="From:"
                    />
                </MuiPickersUtilsProvider>
            </Grid>

            <Grid className={classes.searchDate}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        autoOk
                        ampm={false}
                        disableFuture
                        value={props.stopDate}
                        inputVariant="outlined"
                        format="dd.MM.Y HH:mm"
                        onChange={(date) => { props.callback(props.startDate, date)}}
                        label="To:"
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </FormGroup>
    </FormControl>
}

TimeFrameSelector.propTypes = {
    callback: PropTypes.func.isRequired,
    startDate: PropTypes.object.isRequired,
    stopDate: PropTypes.object.isRequired,
};
