import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import {
    createTheme,
    ThemeProvider
} from '@material-ui/core/styles';

import './App.css';
import Home from './containers/Home';
import Validation from './containers/Validation';
import Dashboard from './containers/Dashboard';
import Upload from './containers/Upload';
import Search from './containers/Search';
import Tokens from './containers/Tokens';

import {
    AppContextInterface,
    User,
    DjangoUser,
    BaseStationInterface,
    SnackbarMessage, FilterContextInterface, TelemetryField,
} from './utils/types';
import { Theme } from './styles'
import ProtectedRoute from "./components/ProtectedRoute";
import MySnackBar from "./components/Snackbar";
import useDidUpdateEffect from "./CustomHooks/useDidUpdateEffect";
import { getBaseStations } from "./actions/basestations/getInfo";

export const AppContext = createContext<AppContextInterface>(null as unknown as AppContextInterface);
export const FilterContext = createContext<FilterContextInterface>(null as unknown as FilterContextInterface);

function App() {
    const [user, setUser] = useState<User>({
        name: '',
        email: '',
        token: {
            value: '',
            expiry: new Date(),
        },
        upload: false,
        download: false,
        support_email: "",
    });
    const [isLoading, setLoading] = useState<boolean>(true);
    const [baseStations, setBaseStations] = useState<BaseStationInterface[]>([]);
    const [sensors, setSensors] = useState<string[]>([]);
    const [telemetryFields, setTelemetryFields] = useState<TelemetryField[]>([]);
    const [searchBaseStation, setSearchBaseStation] = useState<BaseStationInterface | null>(null);
    const [snackBar, setSnackBar] = useState<SnackbarMessage>({
        open: false,
        msg: '',
        variant: 'info',
        timeout: 3000,
    });

    const [theme] = useState(
        createTheme({
            palette: {
                primary: {
                    ...Theme.primaryColor
                },
                secondary: {
                    ...Theme.errorColor
                },
            },
        })
    );

    const handleSnackBarClose = (e, reason) => {
        if (reason && reason === "clickaway" && snackBar.timeout === null) {
            // do not close snackBar on click away if it has no timeout
            return
        }
        setSnackBar({
            open: false,
            msg: '',
            variant: snackBar.variant,
            timeout: 3000,
        });
    }

    const setDjangoUser = (du: DjangoUser) => {
        setTelemetryFields(du.telemetry_fields)
        setUser({
            name: du.name,
            email: du.email,
            token: {
                value: du.token,
                expiry: new Date(du.expiry),
            },
            upload: du.upload === 'true',
            download: du.download === 'true',
            support_email: du.support_email,
        })
    }

    useEffect(() => {
        // @ts-ignore
        if (window.django_user !== undefined) {
            setDjangoUser(django_user)
        }
        getBaseStations().then((reply) => {
            if (reply.error) {
                setLoading(false)
                setSnackBar({
                    open: true,
                    msg: "Server error, please try again later.",
                    variant: "error",
                    timeout: 3000,
                });
                return
            }
            setBaseStations(reply.baseStations)
            setSensors(reply.sensorNames)
        })
    }, [])

    useDidUpdateEffect(() => {
        if (baseStations.length > 0) {
            setLoading(false)
        }
    }, [baseStations])

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <AppContext.Provider
                    value={{
                        isLoading,
                        user,
                        setUser,
                        baseStations,
                        setBaseStations,
                        sensors,
                        setSensors,
                        setSnackBar,
                        searchBaseStation,
                        setSearchBaseStation,
                        telemetryFields,
                    }}
                >
                    <MySnackBar
                        timeout={snackBar.timeout}
                        variant={snackBar.variant}
                        message={snackBar.msg}
                        open={snackBar.open}
                        onClose={handleSnackBarClose} />
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>

                        <Route exact path="/validation">
                            <Validation />
                        </Route>

                        <Route exact path="/dashboard">
                            <Dashboard />
                        </Route>

                        <ProtectedRoute exact path="/search" component={Search} />
                        <ProtectedRoute exact path="/upload" component={Upload} />
                        <ProtectedRoute exact path="/tokens" component={Tokens} />

                        <Redirect to="/" />
                    </Switch>
                </AppContext.Provider>
            </ThemeProvider>
        </Router>
    );
}

export default App;
