import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Widget(props:{size:number, className:string}) {
    return <div className={props.className}> <CircularProgress size={props.size} /> </div>
}

Widget.propTypes = {
    size: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
};
