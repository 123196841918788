import {META_DATA_VALIDATION} from "../../constants/URLS";
import {TELEMETRY_VALIDATION} from "../../constants/URLS";

export const validateMetaData = async data => {
    const resp = await fetch(
        META_DATA_VALIDATION,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data
        })
        .then(resp => resp.json())
    return JSON.stringify(resp, null, 2)
}

export const validateTelemetry = async data => {
    let resp = await fetch(
        TELEMETRY_VALIDATION,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:data
        })
        .then(resp => resp.json())
    return JSON.stringify(resp, null, 2)
}
