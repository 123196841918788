import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AppContext } from "../App";
import Grid from '@material-ui/core/Grid/Grid';
import {
    BaseStationInterface,
    ChartType,
    CustomSearch, logicalOperatorValues,
    SearchCheckedItems,
    TelemetryField,
    WidgetInfo,
} from "../utils/types";
import Button from '@material-ui/core/Button/Button';
import { Elements, Inputs, Typography as MyTypography } from "../styles";
import ClearIcon from '@material-ui/icons/Clear';
import TimeFrameSelector from "./TimeFrameSelector";
import LocationSelector from "./LocationSelector";
import SensorsSelector from "./SensorsSelector";
import DialogWrapper from "./DialogWrapper";
import { getCheckedItemsNames, setDefaultCheckedState, DateToString } from "../utils/helpers";
import { Checkbox, FormControl, FormControlLabel, FormLabel, MenuItem, Select, Tooltip } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField/TextField";
import { colors } from "../styles/colors";
import AdvancedSearch from "./AdvancedSearch";
import useDidUpdateEffect from "../CustomHooks/useDidUpdateEffect";
import AddBoxRounded from "@material-ui/icons/AddBoxRounded";
import EditRounded from '@material-ui/icons/EditRounded';
import HelpOutline from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles(theme => ({
    searchButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
            margin: '0 10px',
            minWidth: '160px'
        },
    },
    telemetryGroup: {
        marginTop: '10px',
        '&.MuiFormHelperText-root, .MuiFormHelperText-contained, .MuiFormHelperText-marginDense': {
            marginLeft: '6px'
        },
    },
    telemetryInput: {
        maxWidth: '80%',
    },
    logicalButton: {
        borderRadius: '50%',
        minWidth: '30px',
        minHeight: '30px',
        maxWidth: '30px',
        maxHeight: '30px',
        backgroundColor: '#65bc7b',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: colors.selected,
            color: '#fff'
        }
    },
    customSearchWrapper: {
        marginBottom: '10px',
    },
    customSearchPairsWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chartForm: {
        // marginTop: '20px'
        width: '80%'
    },
    chartLabel: {
        marginBottom: '10px'
    },
    chartSelector: {
        minWidth: '200px',
        maxHeight: '45px'
    },
    separator: {
        marginBottom: '15px',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            marginLeft: '8px'
        }
    },
    tooltip: {
        fontSize: '1.6em',
        fontWeight: 300,
        lineHeight: '1.3em'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    manualTelemetry: {
        minWidth: '100%'
    }
}));

function TelemetryFilter(props) {
    const app = useContext(AppContext);
    const classes = useStyles();
    const [startDate, setStartDate] = useState(new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        new Date().getDate(),

    ));
    const [stopDate, setStopDate] = useState(new Date());
    const [checkedBaseStations, setCheckedBaseStations] = useState<SearchCheckedItems>({});
    const [checkedSensors, setCheckedSensors] = useState<SearchCheckedItems>({});
    const [telemetryKey, setTelemetryKey] = useState<string>('');
    const [telemetryLabel, setTelemetryLabel] = useState<string>('');
    const [customSearch, setCustomSearch] = useState<CustomSearch[]>([])
    const [chartType, setChartType] = useState<number>(ChartType.Line)
    const [canSend, setCanSend] = useState<boolean>(false);
    const [sensorIDs, setSensorIDs] = useState<string[]>([]);
    const [customQuery, setCustomQuery] = useState<string[][]>([]);
    const [isUpdate, setUpdate] = useState<boolean>(false);
    const [isManualTelemetry, setManualTelemetry] = useState<boolean>(false);
    const [isFirstLoad, setFirstLoad] = useState<boolean>(true);
    const [title, setTitle] = useState<string>('');

    const handleBaseStationChange = (values) => {
        setCheckedBaseStations(values);
    };

    const handleSensorChange = (values) => {
        setCheckedSensors(values);
    };

    const makeParams = (startSearch?: boolean): string[][] => {
        const params: string[][] = [];
        // get location of selected base stations
        const selectedBaseStationNames: string[] = [];
        const allBS = [...app.baseStations];
        for (const key in checkedBaseStations) {
            if (checkedBaseStations.hasOwnProperty(key) && checkedBaseStations[key]) {
                const bs = allBS.find(s => s.longName === key);
                if (bs) {
                    // add location to params and remove found baseStation from search array
                    selectedBaseStationNames.push(bs.position.long + ',' + bs.position.lat);
                    allBS.splice(allBS.indexOf(bs), 1);
                }
            }
        }

        // if at least one base station is selected
        if (selectedBaseStationNames.length > 0) {
            selectedBaseStationNames.forEach((loc: string) => {
                params.push(['location', loc]);
            })
        } else if (props.forceLocation) {
            // if any location selected and forceLocation is true
            allBS.forEach((bs: BaseStationInterface) => {
                params.push(['location', bs.position.long + ',' + bs.position.lat]);
            })
        }

        if (Object.keys(checkedSensors).find(key => !checkedSensors[key])) {
            params.push(['sensors', sensorIDs.join(',')]);
        }

        if (customQuery.length > 0) {
            params.push(...customQuery);
        }

        if (telemetryKey !== "") {
            params.push(['key', telemetryKey]);
        }

        if (telemetryLabel !== "") {
            params.push(['labelKey', telemetryLabel])
        }
        
        params.push(['startTime', DateToString(startDate)]);
        params.push(['stopTime', DateToString(stopDate)]);

        // reset filter after adding Widget
        if (startSearch) {
            resetFilter()
        }
        return params;
    }

    const resetFilter = () => {
        setStartDate(new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 1,
            new Date().getDate(),
        ));
        setStopDate(new Date());
        setTelemetryKey('');
        setTelemetryLabel('');
        setCustomSearch([]);
        setChartType(ChartType.Line)
        setTitle('');
        setUpdate(false);
        setCheckedBaseStations(setDefaultCheckedState(app.baseStations.map(s => s.longName), false));
        setCheckedSensors(setDefaultCheckedState([...app.sensors, "Base Station"], false))
        setFirstLoad(true)
    }

    const prepareQueryAndClose = (execute: boolean) => {
        if (!canSend || !execute) {
            if (isUpdate) {
                resetFilter()
            }
            props.onClose();
            return
        }

        let splitKey = telemetryKey.split('.');
        let key = telemetryKey;
        if (splitKey.length > 1) {
            key = splitKey[splitKey.length - 1];
        }

        // return widget
        props.onSuccess({
            title: title,
            sensorName: Object.keys(checkedSensors).find(key => checkedSensors[key]),
            startDate: startDate,
            stopDate: stopDate,
            sensors: getCheckedItemsNames(checkedSensors),
            locations: getCheckedItemsNames(checkedBaseStations),
            fullKey: telemetryKey,
            labelKey: telemetryLabel,
            key: key,
            type: chartType,
            customSearch: customSearch,
            params: makeParams(false)
        });
        resetFilter()
    }

    const handleTimeFrame = (dStart: Date, dStop: Date) => {
        if (dStart.valueOf() !== startDate.valueOf()) {
            // start date change
            if (dStart.valueOf() > dStop.valueOf()) {
                const newStopDate = new Date(dStart)
                newStopDate.setHours(23, 59, 59, 0)
                setStartDate(dStart)
                setStopDate(newStopDate)
            } else {
                setStartDate(dStart)
            }
            return
        }

        if (dStop.valueOf() !== stopDate.valueOf()) {
            // stop date changed
            if (dStart.valueOf() > dStop.valueOf()) {
                const newStartDate = new Date(dStop)
                newStartDate.setHours(0, 0, 0, 0)
                setStartDate(newStartDate)
                setStopDate(dStop)
            } else {
                setStopDate(dStop)
            }
        }
    }

    const handleChartTypeChange = e => {
        setChartType(+e.target.value);
    }

    const handleTelemetryFieldChange = e => {
        setTelemetryKey(e.target.value)
        if (e.currentTarget.dataset.label !== "") {
            setTelemetryLabel(e.currentTarget.dataset.label)
        }
    }

    const handleManualTelemetry = e => {
        setManualTelemetry(e.target.checked)
    }

    const getTelemetryItems = () => {
        const sensor_name:string|undefined = Object.keys(checkedSensors).find(key => checkedSensors[key] === true)?.toLowerCase();
        const telemetryFields:TelemetryField[] = app.telemetryFields; 
       /*  const telemetryFields:TelemetryField[] = [{
            name: "CPU Temperature",
            field_name: "computingUnit.cpuTemperatur",
            sensor: "bioacustic",
        }] */
        const menuItems:JSX.Element[] = [];
        telemetryFields.forEach((tf:TelemetryField) => {
            const tfs:string = tf.sensor === 'basestation' ? 'base station' : tf.sensor;
            if (sensor_name === tfs) {
                menuItems.push(<MenuItem value={tf.field_name} data-label={tf.field_label} key={tf.field_name}>{tf.name}</MenuItem>);
            }
        })

        return menuItems.length !== 0 ? menuItems : <MenuItem value=""><em>None</em></MenuItem>
    }

    const isSensorSelected = (): boolean => {
        for (const k in checkedSensors) {
            if (checkedSensors.hasOwnProperty(k)) {
                if (checkedSensors[k] === true) return true
            }
        }
        return false;
    }

    const setDataFromWidget = (widget: WidgetInfo) => {
        setStartDate(widget.startDate)
        setStopDate(widget.stopDate)
        let newSensors = { ...checkedSensors };
        widget.sensors.forEach(sensor => {
            for (const k in newSensors) {
                if (newSensors.hasOwnProperty(k)) {
                    newSensors[k] = k === sensor
                }
            }
        })
        setCheckedSensors(newSensors)

        let newLocations = { ...setDefaultCheckedState(app.baseStations.map(s => s.longName), false) }
        widget.locations.forEach(loc => {
            for (const k in newLocations) {
                if (newLocations.hasOwnProperty(k)) {
                    if (!newLocations[k]) {
                        newLocations[k] = k === loc
                    }
                }
            }
        })
        setTitle(widget.title)
        setCheckedBaseStations(newLocations)
        setTelemetryKey(widget.fullKey)
        setTelemetryLabel(widget.labelKey)
        setChartType(widget.type)
        setCustomSearch(widget.customSearch)
        setUpdate(true)
    }

    useDidUpdateEffect(() => {
        if (props.widget) setDataFromWidget(props.widget)
    }, [props.widget])

    useEffect(() => {        
        if (Object.keys(checkedSensors).find(key => checkedSensors[key])) {
            // reset telemetry key when sensor changed, but not for the first load
            if (isFirstLoad) {
                setFirstLoad(false)
            } else {
                setTelemetryKey('')
            }
            // if one of the sensors is not selected
            let tempIDs: string[] = [];
            let isAnyLocation:boolean = true;

            for (const key in checkedBaseStations) {
                if (isAnyLocation) {
                    isAnyLocation = !(checkedBaseStations.hasOwnProperty(key) && checkedBaseStations[key] === true)
                }
            }

            Object.keys(checkedSensors).forEach(key => {
                if (checkedSensors[key] === true) {
                    app.baseStations.forEach(bs => {
                        // add base station ID for Base Station source
                        if (key === "Base Station") {
                            if (!isAnyLocation) {
                                for (const key in checkedBaseStations) {
                                    if (checkedBaseStations.hasOwnProperty(key) && checkedBaseStations[key] === true) {
                                        const bs = app.baseStations.find(s => s.longName === key);
                                        if (bs) {
                                            tempIDs.push(bs.id)
                                        }
                                    }
                                }
                            } else {
                                tempIDs.push(bs.id)
                            }
                            return
                        }

                        // add sensor ID
                        bs.sensors.forEach(s => {
                            if (s.type.toLowerCase() === key.toLowerCase()) {
                                tempIDs.push(s.id)
                            }
                        })
                    })
                }
            })
            tempIDs = tempIDs.filter((e, i) => tempIDs.indexOf(e) === i)
            setSensorIDs(tempIDs)
        } else {
            setSensorIDs([])
            setTelemetryKey('')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.baseStations, checkedSensors, checkedBaseStations])

    useEffect(() => {
        const cq: string[][] = [];
        customSearch.forEach(cs => {
            const key: string = cs.key.trim();
            const value: string = cs.value.trim();
            let q: string = key + ',' + value;
            if (key !== '' && value !== '') {
                q += ',' + logicalOperatorValues[cs.operator].query;
                cq.push(['cs', String(q)]);
            }
        })
        setCustomQuery(cq)
    }, [customSearch])

    useEffect(() => {
        setCheckedBaseStations(setDefaultCheckedState(app.baseStations.map(s => s.longName), false));
        setCheckedSensors(setDefaultCheckedState([...app.sensors, "Base Station"], false))
    }, [app.baseStations, app.sensors])

    useDidUpdateEffect(() => {
        setCanSend(telemetryKey !== '' && isSensorSelected() && title !== '')
    }, [checkedSensors, telemetryKey, title])


    return (
        <DialogWrapper open={props.open} onClose={() => { prepareQueryAndClose(false) }} title={"Add Widget"}>
            <Grid container spacing={3}>
                {/* LEFT COLUMN */}
                <Grid container item xs={6}>
                    <TimeFrameSelector
                        callback={handleTimeFrame}
                        startDate={startDate}
                        stopDate={stopDate}
                    />

                    <LocationSelector
                        values={checkedBaseStations}
                        allowAny={true}
                        callback={handleBaseStationChange} />

                    <div className={classes.separator}></div>

                    <FormControl variant="outlined" className={classes.chartForm}>
                        <FormLabel component="legend" className={classes.chartLabel}>Chart Type</FormLabel>
                        <Select
                            className={classes.chartSelector}
                            value={chartType}
                            onChange={handleChartTypeChange}
                        >
                            <MenuItem value={ChartType.Line}>Line</MenuItem>
                            <MenuItem value={ChartType.Bar}>Bar</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* RIGHT COLUMN */}
                <Grid item xs={6} md={6}>
                    <SensorsSelector
                        sensors={[...app.sensors, "Base Station"]}
                        values={checkedSensors}
                        selectOne={true}
                        callback={handleSensorChange} />

                    
                    <FormLabel component="legend">Widget Title *</FormLabel>
                    <FormGroup className={classes.telemetryGroup}>
                        <TextField variant="outlined"
                            size="small"
                            value={title}
                            className={classes.telemetryInput}
                            onChange={(e) => {
                                setTitle(e.target.value)
                            }} />
                    </FormGroup>
                    <div className={classes.separator}></div>

                    <FormControl variant="outlined" className={!isManualTelemetry ? classes.chartForm : classes.manualTelemetry} >
                        <FormLabel component="legend" className={classes.chartLabel}>Telemetry *</FormLabel>

                        <FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={isManualTelemetry}
                                        onChange={handleManualTelemetry}
                                        name="useManualTelemetry"
                                        color="primary"
                                    />
                                    }
                                    label="Enter telemetry manually"
                                />
                            </FormGroup>
                        </FormControl>

                        <div className={classes.separator}></div>
                        
                        {!isManualTelemetry ?
                            // telemetry drop down
                            <Select
                                className={classes.chartSelector}
                                value={telemetryKey}
                                onChange={handleTelemetryFieldChange}
                            >
                                {getTelemetryItems()}
                            </Select>
                            :
                            // manual telemetry
                            <div>
                                <div className={classes.flex}>
                                    <FormLabel component="legend">Field Name *</FormLabel>
                                    <Tooltip 
                                            title={<h3 className={classes.tooltip}>This should be a field 
                                            in your telemetry JSON file, that is a number.<br />
                                            <strong>Example:</strong><br /><i>processing.system.storage.used</i></h3>}
                                            arrow={true}
                                            placement='top'
                                        >
                                            <span>
                                                <HelpOutline />
                                            </span>
                                    </Tooltip>  
                                </div>
                                    
                                <FormGroup className={classes.telemetryGroup}>
                                    <TextField variant="outlined"
                                        size="small"
                                        helperText="A field that is a number, case sensitive"
                                        value={telemetryKey}
                                        className={classes.telemetryInput}
                                        onKeyDown={(e) => {
                                            if (!e.key.match(/[a-zA-Z0-9.\-_]/)) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setTelemetryKey(e.target.value)
                                        }} />
                                </FormGroup>

                                <div className={classes.separator}></div>
                                <div className={classes.flex}>
                                    <FormLabel component="legend">Field Label</FormLabel>
                                    <Tooltip 
                                            title={<h3 className={classes.tooltip}>When the telemetry field is inside the array, then you must specify a field name that should be used as a label. Label field is a key inside the array item.<br /><strong>Example:</strong><br /><i>filesystem</i></h3>}
                                            arrow={true}
                                            placement='top'
                                        >
                                            <span>
                                                <HelpOutline />
                                            </span>
                                    </Tooltip>  
                                </div>
                            
                                <FormGroup className={classes.telemetryGroup}>
                                    <TextField variant="outlined"
                                        size="small"
                                        helperText="Specify field to use as a label for arrays"
                                        value={telemetryLabel}
                                        className={classes.telemetryInput}
                                        onKeyDown={(e) => {
                                            if (!e.key.match(/[a-zA-Z0-9\-_]/)) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setTelemetryLabel(e.target.value)
                                        }} />
                                </FormGroup>
                            </div>
                        }


                    </FormControl>
                    
                    
                    <div className={classes.separator}></div>

                </Grid>

                <AdvancedSearch customSearch={customSearch} callback={setCustomSearch} />

                <Grid item xs={12} className={classes.searchButtonWrapper}>
                    <Button
                        variant="contained"
                        id="search-button"
                        className={`${props.classes.buttonCancel}`}
                        startIcon={<ClearIcon />}
                        onClick={resetFilter}
                    >
                        Reset Settings
                    </Button>

                    <Button
                        variant="contained"
                        id="search-button"
                        disabled={!canSend}
                        className={`${props.classes.button}`}
                        startIcon={isUpdate ? <EditRounded /> : <AddBoxRounded />}
                        onClick={() => { prepareQueryAndClose(true) }}
                    >
                        {isUpdate ? 'Update' : 'Add'}
                    </Button>
                </Grid>

            </Grid>
        </DialogWrapper>
    );
}

TelemetryFilter.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    widget: PropTypes.object,
    forceLocation: PropTypes.bool,
};

const globalStyles = theme => ({
    ...MyTypography(theme),
    ...Inputs(theme),
    ...Elements(),
});

// @ts-ignore
export default withStyles(globalStyles)(TelemetryFilter)
