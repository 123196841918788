import { SEARCH_URL } from "../../constants/URLS";
import {
    AppContextInterface,
    SearchResultRaw,
    SearchResults,
    SearchResultsInfo,
    UnitSizes
} from "../../utils/types";
import { RefreshToken } from "../../utils/helpers";

const convertSize = (response: SearchResultRaw): SearchResultsInfo => {
    let resultsInfo: SearchResultsInfo = {
        total_size: response.file_size,
        file_size: response.file_size,
        size_unit: UnitSizes.MB,
        pages: response.pages,
        count: response.count,
        total_records: response.total_records,
    }

    if (response.file_size < 1) {
        resultsInfo.total_size = response.file_size * 1000;
        resultsInfo.file_size = Math.round(response.file_size * 1000 * 100) / 100;
        resultsInfo.size_unit = UnitSizes.KB;
    } else {
        if (response.file_size > 1000) {
            resultsInfo.total_size = response.file_size / 1000;
            resultsInfo.file_size = Math.round(response.file_size / 1000 * 100) / 100;
            resultsInfo.size_unit = UnitSizes.GB;
        }
        if (resultsInfo.file_size > 1000) {
            resultsInfo.total_size = response.file_size / 1000;
            resultsInfo.file_size = Math.round(resultsInfo.file_size / 1000 * 100) / 100;
            resultsInfo.size_unit = UnitSizes.TB;
        }
    }
    return resultsInfo
}

const showErr = (app: AppContextInterface) => {
    app.setSnackBar({
        open: true,
        msg: "Server error, please try again later.",
        variant: "error",
        timeout: 3000,
    });
}
const showNothingFound = (app: AppContextInterface) => {
    app.setSnackBar({
        open: true,
        msg: "No records found",
        variant: "info",
        timeout: 3000,
    });
}

export const search = async (app: AppContextInterface, params: string[][]): Promise<SearchResults> => {
    const url = SEARCH_URL + '?' + new URLSearchParams(params).toString();
    try {
        const body = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': app.user.token.value,
            }
        };
        const response: SearchResultRaw | null = await fetch(
            url,
            body
        ).then(async resp => {
            if (resp.status === 401) {
                const newToken: string = await RefreshToken(app, true);
                if (newToken === '') return null;
                body.headers['x-access-token'] = newToken;
                return await fetch(
                    url,
                    body
                ).then(resp => resp.json())
            }
            return resp.json()
        });

        if (response === null || response.data.length === 0) {
            if (response && response.data.length === 0) {
                showNothingFound(app);
            }
            return {
                info: {
                    total_size: 0,
                    file_size: 0,
                    size_unit: UnitSizes.MB,
                    pages: 0,
                    count: 0,
                    total_records: 0,
                },
                data: [],
            };
        }

        return {
            info: convertSize(response),
            data: response.data.map(d => ({
                checked: true,
                data: d
            }))
        }

    } catch (error) {
        console.log(error)
        showErr(app);
        return {
            info: {
                total_size: 0,
                file_size: 0,
                size_unit: UnitSizes.MB,
                pages: 0,
                count: 0,
                total_records: 0,
            },
            data: [],
        };
    }

}
