import {useEffect, useRef} from "react";

// trigger the effect after the first render
export default function useDidUpdateEffect(effect, elements:any[]) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current)
            effect();
        else
            didMountRef.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, elements);
}
