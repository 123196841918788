import {FormControl, FormLabel} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import {CustomSearch, logicalOperator, logicalOperatorValues} from "../utils/types";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {colors} from "../styles/colors";

const useStyles = makeStyles(theme => ({
    fromControlMaxWidth: {
        minWidth: '100%',
        marginBottom: '15px',
    },
    formLabel: {
        marginBottom: '10px'
    },
    formControl: {
        minWidth: 120,
        marginBottom: '15px',
    },
    formGroup: {
        flexDirection: 'row',
    },
    customSearchPairsWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    customKeyValue: {
        display: 'flex',
        alignItems: 'center',
        margin: '5px 0',
    },
    keyWrapper: {
        paddingRight: '15px',
        maxWidth: '170px',
    },
    valueWrapper: {
        marginLeft: '15px',
        maxWidth: '170px',
    },
    addCustomKeyButton: {
        fontWeight: 'bold',
        maxWidth: '200px',
        backgroundColor: colors.main,
        '&:hover': {
            backgroundColor: colors.selected,
            color: '#fff'
        }
    },
    logicalButton: {
        borderRadius: '50%',
        minWidth: '30px',
        minHeight: '30px',
        maxWidth: '30px',
        maxHeight: '30px',
        backgroundColor: '#65bc7b',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: colors.selected,
            color: '#fff'
        }
    },
    customSearchWrapper: {
        marginBottom: '10px',
    },
}))

export default function AdvancedSearch(props:{customSearch:CustomSearch[], callback:(cs:CustomSearch[])=>void}) {
    const classes = useStyles();
    const { customSearch, callback } = props;

    const updateCustomSearch = <K extends keyof CustomSearch>( index: number, value:CustomSearch[K], propName:K ): void => {
        callback(customSearch.map((cs,i) => {
            if (i === index) {
                cs[propName] = value;
            }
            return cs;
        }))
    }

    const changeOperator = (index:number) => {
        callback(customSearch.map((cs,i) => {
            if (i === index) {
                cs.operator = cs.operator+1 > logicalOperatorValues.length - 1 ? 0 : cs.operator + 1;
            }
            return cs;
        }))
    }

    const addCustomPair = () => {
        callback([...customSearch, {
            key: "",
            value: "",
            operator: logicalOperator.equal,
        }]);
    }

    const removeCustomPair = (index:number) => {
        const temp = [...customSearch];
        temp.splice(index, 1);
        callback(temp);
    }

    return <Grid container spacing={3} className={classes.customSearchWrapper}>
        <Grid item xs={12} md={12}>
            <FormControl component="fieldset" className={classes.fromControlMaxWidth}>
                <FormLabel component="legend" className={classes.formLabel}>
                    Advanced Filter
                </FormLabel>
                <Grid container item xs={12} className={classes.customSearchPairsWrapper}>
                    {customSearch.map((cs, i) =>
                        <Grid item xs={6} className={classes.customKeyValue} key={`cs-${i}`} >
                            <TextField label="Key" variant="outlined"
                                       size="small" value={cs.key}
                                       className={classes.keyWrapper}
                                       onKeyDown={(e) => {
                                           if (!e.key.match(/[a-zA-Z0-9.\-_]/)) {
                                               e.preventDefault();
                                               e.stopPropagation();
                                           }
                                       }}
                                       onChange={(e)=>{
                                           updateCustomSearch(i, e.target.value, 'key')
                                       }}/>
                            <Button
                                variant="contained"
                                className={classes.logicalButton}
                                onClick={() => {changeOperator(i)}}
                            >
                                {logicalOperatorValues[cs.operator].string}
                            </Button>
                            <TextField label="Value" variant="outlined"
                                       size="small" value={cs.value}
                                       className={classes.valueWrapper}
                                       onKeyDown={(e) => {
                                           if (!e.key.match(/[a-zA-Z0-9.\-_/: ]/)) {
                                               e.preventDefault();
                                               e.stopPropagation();
                                           }
                                       }}
                                       onChange={(e)=>{
                                           updateCustomSearch(i, e.target.value, 'value')
                                       }} />
                            <IconButton color="secondary" aria-label="Remove" component="span"
                                        onClick={()=>{removeCustomPair(i)}}>
                                <RemoveCircleIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
                <Button
                    variant="contained"
                    className={classes.addCustomKeyButton}
                    onClick={addCustomPair}>
                    Add key value pair
                </Button>
            </FormControl>
        </Grid>
    </Grid>
}

AdvancedSearch.propTypes = {
    callback: PropTypes.func.isRequired,
};
