import red from "@material-ui/core/colors/red";

export const theme = {
    primaryColor: {
        light: '#50555e',
        main: '#282c34',
        dark: '#00000d',
        contrastText: '#fff',
    },
    errorColor: {
        main: red[900],
        contrastText: '#fff'
    }
};
