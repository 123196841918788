import React, {useContext, useEffect, useState, useMemo, useRef} from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import styled from 'styled-components'
import { Paper } from "@material-ui/core";
import {
    AppContextInterface,
    BaseStationInterface,
    HomeFilter,
    SensorInfo,
} from "../../utils/types";
import {AppContext} from "../../App";
import FilterTable from "./FilterTable";

const StyledPaper = styled(Paper)`
  max-width: fit-content;
  max-height: 75vh;
  overflow-y: auto;
`

function HomeFilterTable({ setFilteredBaseStations, filters, onFilter }:{
    setFilteredBaseStations: (bs:BaseStationInterface[])=>void,
    filters: HomeFilter[],
    onFilter:(filters:HomeFilter[])=>void}) {

    const app:AppContextInterface = useContext(AppContext);
    const sensorsLoaded = useRef(false);
    const [sensors, setSensors] = useState<SensorInfo[]>([])
    const [filteredSensors, setFilteredSensors] = useState<SensorInfo[]>([])
    const columns = useMemo(
        () => [
            {
                Header: 'Device ID',
                accessor: 'deviceID',
                width: 150,
                minWidth: 120,
                maxWidth: 150,
            },
            {
                Header: 'Type',
                accessor: 'type',
                width: 200,
                minWidth: 100,
                maxWidth: 200,
            },
            {
                Header: 'Model',
                accessor: 'model',
                width: 450,
                minWidth: 100,
                maxWidth: 450,
            },
            {
                Header: 'Linked base station',
                accessor: 'baseStationLongName',
                width: 250,
                minWidth: 200,
                maxWidth: 250,
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: 150,
                minWidth: 100,
                maxWidth: 150,
            },
        ],
        []
    )

    const handleFilter = (filters:HomeFilter[]) => {
        const noFilter:boolean = filters.length === 0 || (filters.length === 1 && filters[0].value === '');
        let newSensors = [...sensors]
        filters.forEach(f => {
            const fValue = f.value.toLowerCase()
            newSensors = newSensors.filter(s => {
                switch (f.operator) {
                    case 'contains':
                        return String(s[f.column]).toLowerCase().includes(fValue)
                    case 'equals':
                        return String(s[f.column]).toLowerCase() === fValue
                    case 'starts with':
                        return String(s[f.column]).toLowerCase().startsWith(fValue)
                    case 'ends with':
                        return String(s[f.column]).toLowerCase().endsWith(fValue)
                }
                return false
            })
        })
        setFilteredSensors(newSensors)
        // update filters in Home
        onFilter(filters)

        // update filtered baseStations
        let newBaseStations:BaseStationInterface[] = []
        if (noFilter) {
            newBaseStations = [...app.baseStations]
        } else {
            newSensors.forEach(sensor => {
                app.baseStations.forEach(bs => {
                    const foundBs = newBaseStations.find(nbs => nbs.id === bs.id)
                    const foundSensor = bs.sensors.find(bss => bss.id === sensor.deviceID)
                    if (foundBs && foundSensor) {
                        // if base station was already added
                        foundBs.sensors.push({
                            id: foundSensor.id,
                            serialNumber: foundSensor.serialNumber,
                            lastModified: foundSensor.lastModified,
                            type: foundSensor.type,
                            citation: foundSensor.citation,
                            model: foundSensor.model,
                            status: foundSensor.status,
                        })
                    } else if (!foundBs && foundSensor) {
                        newBaseStations.push({
                            id: bs.id,
                            lastModified: bs.lastModified,
                            longName: bs.longName,
                            citation: bs.citation,
                            type: bs.type,
                            position: {
                                long: bs.position.long,
                                lat: bs.position.lat
                            },
                            status: bs.status,
                            sensors: []
                        })
                        newBaseStations[newBaseStations.length - 1].sensors = [{
                            id: foundSensor.id,
                            serialNumber: foundSensor.serialNumber,
                            lastModified: foundSensor.lastModified,
                            type: foundSensor.type,
                            citation: foundSensor.citation,
                            model: foundSensor.model,
                            status: foundSensor.status,
                        }]
                    }
                })
            })
        }
        setFilteredBaseStations(newBaseStations)
    }

    useEffect(() => {
        const updatedSensors:SensorInfo[] = [];
        app.baseStations.forEach((bs) => {
            const bsNameRE = /ammod(\s|_|-)?(.*)/i;
            const bsNameMatch = bs.longName.match(bsNameRE)
            let bsFixedName = bs.longName
            if (bsNameMatch && bsNameMatch.length === 3) {
                bsFixedName = bsNameMatch[2]
            }
            bs.sensors.forEach((sensors) => {
                updatedSensors.push({
                    deviceID: sensors.id,
                    baseStationID: bs.id,
                    baseStationLongName: bsFixedName,
                    baseStationCitation: bs.citation,
                    type: sensors.type,
                    model: sensors.model,
                    citation: sensors.citation,
                    status: sensors.status === 'public' ? 'active' : 'inactive',
                })
            })
        })
        setSensors(updatedSensors)
        setFilteredSensors([...updatedSensors])
    },[app.baseStations])

    useEffect(() => {
        if (filteredSensors.length > 0 && !sensorsLoaded.current) {
            sensorsLoaded.current = true
            handleFilter(filters)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filteredSensors])

    return (
        <div>
            <CssBaseline />
            <StyledPaper elevation={2} className="paper">
                <FilterTable columns={columns} data={filteredSensors} homeFilters={filters} onFilter={handleFilter} />
            </StyledPaper>
        </div>
    )
}

export default HomeFilterTable
