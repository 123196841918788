import {USER_LOGOUT_OIDC} from "../../constants/URLS";

export const logout = async () => {
    return await fetch(
        USER_LOGOUT_OIDC,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            credentials: "same-origin",
        })
        .then(async resp => {
             return resp.status;
        })
        .catch(error => {
            console.log(error)
            return null
        })
}